/* Google fonts */

@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


.aleo-font {
  font-family: "Aleo", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.Title-Heading{
	font-size: 2.5rem;
	font-weight:bolder;
}

:root {
  --Accent:#F1F1E7;
  --Primary: #040533;
  --Complementry: #D22532;
  --Secondry: #0C698B;
  --AccentTwo: #D9D9D9;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --RubicFont: "Rubik", sans-serif;
  --AleoFont: "Aleo", serif;
  --BgWhite: #FFFFFF;
  --orange:#F26726;
  --BrownLigeht:#BC955C;
  --DarkGreen: #3D441D;
  --LightGreen:  #519362;
  --Pink:#A50064;
  --DarkPink: #631244;
  --LightPink: #DE1B5F;
  --LightBlue:#55C8EB;
  --DarkRed: #E1251B;
  --Purple: #7B2B83;
  --DarkBlue:#1C1A34;
  --DarkBrown:#C17E3E;
  --LightPink2:#C13593;
  --LightGreen2:#45A948;
  --Meroon: #9C182F;
  --LightBlue2: #8AB7E9;
  --Yellow: --#FFC70A;
  --Blue:#2374BB;
  --DarkYellow:#F8BD1B;
  --DarkMaroon:#421D1D;
  --LightBrown2: #BF8E33;
  --Blue2:#00A19D;
  --Violet:#233268;
  --LightViolet:#9D57A3;
}

.orangeColor{
  background: var(--orange) !important;
}

.brownLight{
  background: var(--BrownLigeht) !important;
}
.DarkGreen{
  background: var(--DarkGreen) !important; 
}
.LightGreen{
  background: var(--LightGreen) !important; 
}
.DarkPink{
  background: var(--DarkPink) !important; 
}
.LightPink{
  background: var(--LightPink) !important; 
}
.LightBlue{
  background: var(--LightBlue) !important; 
}
.DarkRed{
  background: var(--DarkRed) !important; 
}
.Purple{
  background: var(--Purple) !important; 
}
.Pink{
  background: var(--Pink) !important; 
}
.DarkBlue{
  background: var(--DarkBlue) !important; 
}
.DarkBrown{
  background: var(--DarkBrown) !important;
}
.LightPink2{
  background: var(--LightPink2) !important;
}
.LightGreen2{
  background: var(--LightGreen2) !important;
}
.Meroon{
  background: var(--Meroon) !important;
}
.LightBlue2{
  background: var(--LightBlue2) !important;
}
.Yellow{
  background: var(--Yellow) !important;
}
.Blue{
  background:var(--Blue)!important ;
}
.DarkYellow{
  background:var(--DarkYellow)!important ;
}
.DarkMaroon{
  background:var(--DarkMaroon)!important ;
}
.LightBrown2{
  background:var(--LightBrown2)!important ;
}
.Blue2{
  background:var(--Blue2)!important ;
}
.Violet{
  background:var(--Violet)!important ;
}
.LightViolet{
  background:var(--LightViolet)!important ;
}





body{
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none !important;
}
a:hover{
  
}
.mobileSm{
  display: none;
}
.mTop100{
  margin-top: 100px;
}

.accentBg{
  background: var(--Accent);
}

.primaryBg{
  background: var(--Primary);
}
.SecondryBg{
  background: var(--Secondry);
}
.wrapper.bgWhite{
  background: var(--BgWhite);
}

.img-fluid60{
  max-width: 60%;
}

.SearchOverlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.45s ease-in-out;
}

.SearchBgShow{
  visibility: visible;
}

.wrapper{
  width: 100%;
  margin: 0 auto;
  background: var(--Accent);
  position: relative;
}

.Wrappercontainer{
  max-width: 75rem;
}

.menuBg{
  background: var(--Accent);
  color:var(--Primary);
  font-family: "Rubik", sans-serif;
  font-weight: 500;  
}

.menuBg a.nav-link{
  color: var(--Primary);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 90px;
}
.menuBg a.nav-link:hover,
.menuBg a.nav-link.dropdown-toggle.show{
  background: var(--Primary);
  color: var(--Accent);
}
a.navSearch.search-toggle::after{
  width: 52px;
  height: 52px;
  background-color: var(--Primary);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Accent);
  font-size: 25px;
  font-weight: bold;
  content: "\E850";
  font-family: "pharmalinefonts";
  display: none;
  
}


.navSearch.search-toggle.active::after{
  content: "\E854";
  font-family: "pharmalinefonts";
  font-size: 25px;
  font-weight: normal;
  

}

a.navSearch:hover{
  color: var(--Accent);
}
.navbar-nav{
  gap: 20px;
}
.nav-link.dropdown-toggle::after{
  content: "\e800";
  font-family: "pharmalinefonts";
  border: none;
  font-size: 12px;
  font-weight: bold;
}
.menuBg .dropdown-menu[data-bs-popper]{
  width: 100%;
  margin: 0;
  border-radius: 0;
  border-top: 1px solid #ccc;
  border-left: 1px solid #FFF;
  border-right: 1px solid #FFF;

}
.AboutCol {
  padding: 5% 5%;
  color: var(--Primary);
}
.AboutCol h2{
  font-size: 30px;
  margin-bottom: 15px;
}
.AboutCol p{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.AboutCol ul{
  padding: 0;
  margin: 0;
}
.AboutCol ul li{
  padding: 10px 0;
  border-bottom: 1px solid var(--Primary);
  list-style: none;
  width: 50%;
}
.AboutCol ul li a{
  color:var(--Primary) ;
  font-size: 19px;
  font-weight: 500;
}

.ProfileView img{
  width: 190px;
}

.ProfileView p{
  text-align: center;
    padding-top: 15px;
    padding-bottom: 0;
    margin: 0;
    width: 190px;
    line-height: 15px;
}

.homeBanner,
.homeBanner.slide1,
.homeBanner.slide2,
.homeBanner.slide3{
  
  height: 795px;
  width: 100% !important;
  font-family: "Aleo", serif;
  position: relative;

  object-fit: cover !important;
}

.homeBanner.slide1{
  background: url('../assets/images/Slide1.png') no-repeat center;
}

.homeBanner.slide2{
  background: url('../assets/images/Slide2.png') no-repeat center;
  
}

.homeBanner.slide3{
  background: url('../assets/images/Slider3.png') no-repeat center;
  
}

a.getStartedBtn{
  background: var(--Complementry);
  padding: 10px 20px;
  border-radius: 50px;
  color:var(--Accent);
  margin-top: 10px;
  display: inline-block;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-left: 0;
  margin-bottom: 15px;
  border: 3px solid var(--Complementry);
}
a.getStartedBtn:hover{
  background: var(--Accent);
  color: var(--Complementry);
  border: 3px solid var(--Complementry);

}

a.getStartedBtn::after{
  content: "\e802";
  font-family: "pharmalinefonts";
      font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
  
}
.bannerBottomBox{
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
  font-family: "Rubik", sans-serif;
}
.bannerBottomBox ul{
  width: 100%;
  padding: 0;
  margin: -140px auto 0px;
  display: flex;
  gap: 15px;

}
.bannerBottomBox ul li{
  background: var(--Accent);
  width: 33%;
  /* border-top: 6px solid;
  border-image-slice: 3;
  border-image-source: linear-gradient(177deg, #0C698B 0%, #040533 13.33%); */
  list-style: none;
  padding: 12px 30px;
  box-shadow: 0px 4px 4px 0px #00000017 inset;
  font-family: "Rubik", sans-serif;
}

.bannerBottomBox ul li h4{
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.bannerBottomBox ul li p{
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;

}
.bannerBottomArrowRow{
  background: var(--Primary);
  width: 100%;
  padding: 15px;
  height: 114px;
  text-align: center;
}

a.scrollArrowBg{
  padding-bottom: 50px;
  width: 92px;
  height: 72px;
  border-radius: 60px;
  margin: 0 auto;
  color: var(--Accent);
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;

}
a.scrollArrowBg:hover{
  color: var(--Accent);
}



.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 55px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

/*Bx Slider */



.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  zoom: 1;
  
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.home_banner {
   overflow: hidden;
   margin-top:40px;
}

.home_banner .bx-wrapper,
.home_banner .bx-wrapper .bx-viewport{
  margin: 0;
}

/* .home_banner .bxslider_banner li {
    height: 100% !important;
     background-size: cover; 
    
}

.home_banner .baner_content{
      margin: 0;
    z-index: 10;
    padding-top: 10%;
    padding-bottom: 20px;
    padding-left: 100px;
    color: #fff;
    text-align: left;
    height: 100vh;
    width: 40%;

   
} */

.bx-wrapper .bx-pager {
  display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 100px;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #fff;
  text-indent: -9999px;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 20px;
}
.home_banner .bx-wrapper .bx-viewport p{
   color:#fff;
    padding: 0px 0 0px;
    font-size: 30px;
    font-family: var(--AleoFont);
}

.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
    position: absolute;
    bottom: 100px;
    width: 100%;
}


.companyOverview{
  background: var(--AccentTwo);
  display: flex;
}
.companyOverview .left{

  width: 50%;
  filter: drop-shadow(-1px 1px 2px rgba(50, 50, 0, 0.5))
}
.companyOverview .leftbg{
  width: 100%;
    padding: 15% 20% 15% 33%;
    background: var(--Accent);
    box-shadow: var(--shadow-small);
    position: relative;
    clip-path: polygon(0 0%, 0% 100%, 96% 100%, 100% 0%);
    height: 100%;

}


.companyOverview .right{
  width: 50%;
  padding: 5%;
  
}
/* .companyOverview ul.newTabs{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 0;
} */
.companyOverview ul.newTabs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 0;
  justify-content: right;
  text-align: right;
  width: 660px;
}
.companyOverview ul.newTabs li .nav-link{
  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Primary);
  background: transparent;
  
}
.companyOverview ul.newTabs li.nav-item .nav-link{

  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Primary);
  padding: 10px 25px;
  border-radius: 50px; /* Ensure rounded corners even when inactive */
  transition: all 0.25s ease-in-out;
  width: 100%;
  
}
.companyOverview ul.newTabs li.nav-item .nav-link.active{
  background: #0c688c;
  color: #FFF !important;
  padding: 10px 25px;
  border-radius: 50px;
  transition: all .25s ease-in;
  width: 100%;
  /* width: -webkit-fill-available; */
}
.companyOverview ul.newTabs li.nav-item {
 
  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Primary);
  /* border-bottom: 2px solid transparent;
  border-image: linear-gradient(270deg, #F1F1E7 10%, #040533 85.5%, #040533 45.5%);
  border-image-slice: 1; */
  width:fit-content;
 
  
}
.companyOverview ul.newTabs li.nav-item:last-child{
  border: none;
}
.companyOverview ul.newTabs li .nav-link.active::after{
  content: '\e803';
  font-family: "pharmalinefonts";
  font-size: 19px;
  font-weight: normal;
  margin-left: 20px;
}
a.redBtn,
button.redBtn{
  background: var(--Complementry);
  padding: 10px 20px;
  border-radius: 50px;
  color:var(--Accent);
  margin-top: 10px;
  display: inline-block;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  border:2px solid var(--Complementry)
}
a.redBtn:hover{
  background: var(--Accent);
  color: var(--Complementry);
  transition: all .3s ease-in-out;
  border:2px solid var(--Complementry)
}

.previewContent{
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}
.previewContent h2{
  font-size: 28px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  line-height: 50px;
  margin: 0 15% 1rem;
  text-align: center;
  position: relative;
}
.previewContent h2 .leftquote{
    font-size: 35px;
    position: absolute;
    top: -10px;
    left: -50px;
}
.previewContent h2 .rightquote{
   
  font-size: 35px;
  position: absolute;
  bottom:0;
  right: -50PX;
}
.previewContent p{
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  
}

.manufacturingRow{
  background-color: var(--Accent);
  display: flex;
}
.manufacturingRow .left{
  background: var(--Secondry);
  padding: 5% 0 8%;
  text-align: right;
  width: 50%;
}
.manufacturingRow .right{
  
  padding: 5% 5% 3% 5%;
  text-align: left;
  width: 50%;
}
.manufacturingRow .left h3{
  color: var(--Accent);
  font-size: 34px;
  font-weight: 500;
  padding: 0 4.90rem 3rem ;
}
.manufacturingRow .left ul{
  padding: 0;
  max-width: 0;
}
.manufacturingRow ul.MSTabs{
  display: contents;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 0;
}
.manufacturingRow ul.MSTabs li .nav-link.active,
.manufacturingRow ul.MSTabs li .nav-link:hover{
  font-family: "Rubik", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    border: none;
    color: var(--Accent);
    background: var(--Primary);
    padding:1.11rem 4.90rem;
  
}
.manufacturingRow ul.MSTabs li .nav-link{
  padding: 1.11rem 4.90rem;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  text-align: right;
  width: -webkit-fill-available;

  
}
.manufacturingRow ul.MSTabs li .nav-link::after{
  content: '\e803';
  font-family: "pharmalinefonts";
  font-size: 19px;
  font-weight: normal;
  margin-left: 20px;
}

.manufacturingContent{
  padding: 0;
  margin: 0;
  color: var(--Primary);
  font-family: "Rubik", sans-serif;
}
.manufacturingContent h2{
  
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  padding: 0rem 0 1rem;
}
.manufacturingContent h4{
    
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1.1rem;
}
.manufacturingContent p{
  
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 30px;
}

.PackagingServicesRow{
  background-color: var(--Secondry);
  display: flex;
  
}
.PackagingServicesRow .left{
  background: var(--Accent);
  padding: 5% 3% 3% 7% ;
  text-align: right;
  width: 50%;
  box-shadow: var(--shadow-small);
}
.PackagingServicesRow .right{
  
  padding: 5% 0 8%;
  text-align: left;
  width: 50%;
  box-shadow: var(--shadow-small);
}
.PackagingServicesRow .right h3{
  color: var(--Accent);
  font-size: 34px;
font-weight: 500;
padding: 0% 8% 5% 8% ;
}
.PackagingServicesRow .right ul{
  padding: 0;
  max-width: 0;
}
.PackagingServicesRow ul.MSTabs{
  display: contents;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 0;
}

.PackagingServicesRow ul.MSTabs li .nav-link.active,
.PackagingServicesRow ul.MSTabs li .nav-link:hover{
  font-family: "Rubik", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    border: none;
    color: var(--Accent);
    background: var(--Primary);
    padding:1.11rem 8%;
    width: -webkit-fill-available;
}
.PackagingServicesRow ul.MSTabs li .nav-link{
  padding: 1.11rem 8%;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  text-align: left;
  
}
.PackagingServicesRow ul.MSTabs li .nav-link i{
  font-size: 19px;
  margin-right: 20px;
}

.PackagingServicesContent{
  padding: 0;
  margin: 0;
  color: var(--Primary);
  font-family: "Rubik", sans-serif;
}
.PackagingServicesContent h2{
  
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  padding: 0 0px 5%;
}
.PackagingServicesContent h4{
    
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1.1rem;
}
.PackagingServicesContent p{
  
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
}

.PackagingServicesContent p.imagePosition{
  display: flex;
  justify-content: end;
}

.ourProducts{
  background: var(--AccentTwo);
  display: flex;
}
.ourProducts .left{
  width: 50%;
}
.ourProducts .leftbg{
 
   width: 100%;
    padding: 25% 20%;
    background: var(--Complementry);
    box-shadow: var(--shadow-small);
    position: relative;
    clip-path: polygon(0 0%, 0% 100%, 85% 100%, 100% 0%);
    height: 100%;
}
.ourProducts .leftbg {
  font-family: "Aleo", serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: var(--Accent);
}
.ourProducts .right{
  width: 50%;
  padding: 5% 2%;
}
ul.productList{
  padding: 0;
  margin: 0;
  display: grid;
    grid-template-columns: repeat(4, 1fr);
}
ul.productList li {
  list-style: none;
  padding: 10px 0rem;
  display: flex;
  gap: 0;
  width: 150px;
  justify-content: center;
  text-align: center;
}
.ourProducts .leftbg .leftContent {
  width: 320px;
}

ul.productList li a{
  color: var(--Primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
ul.productList li a .icon{
  background: var(--Primary);
  width: 100px;
  height: 100px;
  color: var(--Accent);
  font-size: 4.5rem;
  padding: 0;
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
}

ul.productList li a .icon .ProductSVG{
  height: 82px;
    width: 82px;
    padding: 0;
    /* filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%); */
    filter: invert(1);
}
.productItem  .ProductIcon i .ProductSVG{
  height: 82px;
  width: 82px;
  filter: invert(1);
}

ul.productList li a .icon:hover{
  background: var(--Complementry);
}
ul.productList li a p{ 
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 10px;
}

.qualityAssurance{
  background: var(--BgWhite); 
  font-family: var(--RubicFont) ;
}

.qualityAssuranceImag{
  background: url('../assets/images/QA.png') no-repeat 460px -46px;
    padding: 4rem 0;
    background-size: 70% auto;
    background-attachment: fixed;
    margin-left: 0;
}


 

.qualityAssurance .data{
  width: 50%;
  margin: 0;
  font-size: 2rem;
 
  

}
.qualityAssurance .data h3{
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 2rem;
  
}
.qualityAssurance .data p{
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
}

.qualityAssurance .data h5{
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  margin: 3rem 0 2rem 0;
  
}

ul.gmpList{
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
  align-items: center;

}
ul.gmpList li{
  list-style: none;
}
.Bottom-shadow{
  box-shadow: var(--shadow-medium);
    display: inline-block;
    width: 100%;
}

.workTogether{
  background: var(--Secondry);
  position: relative;

}
.workTogether .left{
  background: url('../assets/images/Pattern_lt.png') no-repeat 460px -46px;
  position: absolute;
    left: 0;
    height: 100%;
    width: 90%;
    opacity: 0.3;
    mix-blend-mode: color-burn;
    transform: rotate(3.087deg);
}
.workTogether .right{
  background: url('../assets/images/Pattern_rt.png') no-repeat 460px -46px;
    position: absolute;
    right: 40px;
    height: 100%;
    width: 12%;
    opacity: 0.3;
    mix-blend-mode: color-burn;
    transform: rotate(3.087deg);
    top: 0;

}
.workTogether .data{
  text-align: center;
  padding: 8rem 0;
  font-family: var(--RubicFont);
  position: relative;
}
.workTogether h3{
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  color: var(--Accent);
  margin-bottom: 25px;
}

.workTogether .data .btnRow{
  margin: 5rem 0;

}
.workTogether .data a.getQuote{
  border: 2px solid var(--Accent);
  padding: 20px 50px;
  border-radius: 50px;
  color: var(--Primary);
  margin: 10px;
  background: var(--Accent);
  font-size: 34px;
  font-weight: 500;
}
.workTogether .data a.getQuote:hover{
  border: 2px solid var(--Accent);
  background: transparent;
  color: var(--Accent);
}

.footerBlock{
  position: relative;
  /* margin-top: 10%; */
}

.footer-top{
  position: absolute;
  background: var(--Primary);
  padding: 2rem;
  font-family: var(--RubicFont);
  clip-path: polygon(200% 0, 100% 101%, 0 101%);
  height: 202px;
  margin-top: -202px;
  top:1px;
  width: 100%;
}
.footer{
  background: var(--Primary);
  padding:5rem 3rem;
  font-family: var(--RubicFont);
  color: var(--Accent);
 
}
.footer h4{
  margin-bottom: 2rem;
  font-size: 19px;
}
.footerRow{
  
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.footercontact{
  display: flex;
    gap: 10px;
    margin: 0 0 1rem 0;
    
    
}

.footercontact .FTicon{
 padding: 0;
 font-size: 35px;
 margin-left: -15px;
}
.footercontact .FTText{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.footercontact .FTText p{
  padding: 0;
  margin: 0;
}

.footercontact .FTText a{
  color: var(--Accent);

}


.CatlogImage{
  margin: 20px auto;
  width: 80%;
}

ul.FTLinks{
  padding: 0;
  margin: 0;
}

ul.FTLinks li{
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;
}
ul.FTLinks li a{
  color: var(--Accent);
}


.BottomBtn{
  position: fixed;
  bottom: 80px;
  right: 30px;
}
.BottomBtn a.BottomGetQuote{
  
    background: var(--Complementry);
    border: 2px solid var(--Accent);
    padding: 20px 43px;
    font-family: var(--RubicFont);
    border-radius: 60px;
    font-weight: 600;
    font-size: 30px;
  color: var(--Accent);
  display: none;
}

.copyrightRow{
  background: var(--Complementry);
  color: var(--Accent);
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.InnerPageBanner{
  margin-top: 100px;
  background: var(--Primary);
  font-size: 16px;
  color: var(--Primary);
  font-family: var(--RubicFont);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}


.breadcrumbs{
  background: var(--Complementry);
  color: var(--Accent);
  padding: 10px;
}
.breadcrumbsBlue{
  background: var(--Secondry);
  color: var(--Accent);
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 11;
  top:100px;
}
.breadcrumbsBlue a{
  color: var(--Accent);
}
.breadcrumbsBlue a:hover{
  color: var(--AccentTwo);
}
.InnerPageBody{
  padding: 100px 0 10%;
  background: #FFFFFF;
}

.topHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.topHeader h1{
  font-size: 34px;
  color: var(--Primary);
  font-family: var(--RubicFont);
  font-weight: 500;
}
.topHeader .right{
  display: flex;
  justify-content: flex-end;
}


.topHeader a.sortByBtn{
  background: var(--Primary);
  padding: 10px 20px; 
  font-family: var(--RubicFont);
  font-size: 19px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--Accent);
  gap: 10px;
}


.topHeader a.sleepSupport i{
  font-size: 13px;
}
.topHeader a.sleepSupport{
  background: var(--Secondry);
  padding: 10px 25px; 
  font-family: var(--RubicFont);
  font-size: 19px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--Accent);
  gap: 10px;
}
.productNum{
  background: var(--AccentTwo);
  color: var(--Primary);
  font-size: 16px;
  font-family: var(--RubicFont);
  padding: 5px 20px;
  font-weight: 500;
}

ul.ProductList{
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap:20px
}
ul.ProductList li.ProductItems{
  list-style: none;
  
  flex-direction: row;
  
}

ul.ProductList li.ProductItems .item {
  display: block;
  width: 100%;
  height: auto;
  background: var(--Accent);
  
  
}

ul.ProductList li.ProductItems .item .pic{
  position: relative;
}

ul.ProductList li.ProductItems .item .pic .ProductOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%)
}

ul.ProductList li.ProductItems .item:hover .pic .ProductOverlay {
  opacity: 1;
}

ul.ProductList li.ProductItems .text {
  color: white;
  font-size: 19px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: var(--RubicFont);
  font-weight: 500;
  width: 80%;
 
}


ul.ProductList li.ProductItems .ItemContent{
  padding: 50px 30px;
  font-family: var(--RubicFont);
}
ul.ProductList li.ProductItems .ItemContent h5{
  font-size: 19px;
  color: var(--Secondry);
  margin: 10px 0;
  font-weight: 500;
  font-family: var(--RubicFont);
}
ul.ProductList li.ProductItems .ItemContent h6{
  font-size: 16px;
  margin: 10px 0;
  color: var(--Primary);
  font-weight: 500;
  font-family: var(--RubicFont);
}

ul.ProductList li.ProductItems .ItemContent a.detailsBtn{
    background: var(--Complementry);
    padding: 5px 20px;
    border-radius: 50px;
    color:var(--Accent);
    margin-top: 10px;
    display: inline-block;
    font-size: 16px;
    font-family: var(--RubicFont);
    font-weight: 500;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
    border:2px solid var(--Complementry)
  }
  ul.ProductList li.ProductItems .ItemContent a.detailsBtn:hover{
    background: var(--Accent);
    color: var(--Complementry);
    border:2px solid var(--Complementry)
  }

  /* Product Info  */
  .productInfo{
    padding: 0;
    margin: 0;
  }
  .productInfo h2{
    font-size: 34px;
    font-family: var(--RubicFont);
    color: var(--Primary);
    font-weight: 500;
    margin: 10px 0 20px;
  }
  .productInfo h3{
    font-size: 19px;
    font-family: var(--RubicFont);
    color: var(--Primary);
    font-weight: 700;
    margin: 10px 0 25px;
  }
  .productInfo p.text{
    font-size: 16px;
    font-family: var(--RubicFont);
    color: var(--Primary);
    margin: 20px 0 35px;
    font-weight: 400;
  }

  .productInfo p.text2{
    font-size: 16px;
    font-family: var(--RubicFont);
    color: var(--Primary);
    margin: 0px 0 5px;
    font-weight: 400;
  }
  .productInfo p.text2 b{
    font-weight: 500;
  }

  .productInfo h4{
    font-size: 24px;
    font-family: var(--RubicFont);
    color: var(--Primary);
    font-weight: 500;
    margin: 20px 0 30px;
  }


  .product-slider .slider.slider-for {
    max-width: 100%;
}
.product-slider .slider.slider-nav {
    max-width: 100%;
}
.product-slider .slider.slider-for,
.product-slider .slider.slider-nav {
    width: 100%;
}
.product-slider .slider.slider-for{
    padding: 0 5px;
}
.product-slider .slick-slide img {
    width: 100%;
}
.product-slider .slider-banner-image {
    height: auto;
    width: 100%;
}
.product-slider .slider.slider-nav {
    padding: 20px 0 0;
}
.product-slider .slider-nav .slick-slide.thumbnail-image .thumbImg img {
    height: 100%;
    width:100%;
    object-fit: cover;
}
.product-slider .slider-banner-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}
.product-slider .slick-vertical .slick-slide:active,
.product-slider .slick-vertical .slick-slide:focus,
.product-slider .slick-arrow:hover,
.product-slider .slick-arrow:focus {
    border: 0;
    outline: 0;
}
.product-slider .slider-nav .slick-slide.slick-current span {
    color: #196DB6;
}
.product-slider .slider-nav .slick-slide {
    text-align: center;
}
.product-slider .slider-nav .slick-slide span {
    font-size: 14px;
    display: block;
    padding: 5px 0 15px;
}
.product-slider .slick-arrow {
    width: 100%;
    background-color: transparent;
    border: 0;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    height: 18px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
}

.product-slider .slider.slider-nav .thumbImg {
    padding: 15px 20px 15px;
}
.product-slider .slider.slider-nav .thumbImg img {
  opacity: .5;
}
.slider-nav .slick-slide.slick-current.slick-active .thumbImg img {
  
    opacity: 1;
}


a.viewProductDetailsLink{
  padding: 0;
  margin: 20%;
  color: var(--Primary);
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
a.viewProductDetailsLink i{
  font-size: 15px;
}

.viewProductDetailsTabs{
  margin-bottom: 60px;
}


.viewProductDetailsTabs ul.newTabs{
  padding: 0;
  margin: 60px 0 0; 
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
}

.viewProductDetailsTabs ul.newTabs li.nav-item a{
  padding: 20px 0;
  background: var(--Primary);
  color: var(--Accent);
  font-size: 22px;
  font-weight: 400;
  font-family: var(--RubicFont);
  border-radius:0;
  border: 0;
}
.viewProductDetailsTabs ul.newTabs li.nav-item a.active{
  
  background: var(--Secondry);
  border: 0;
  position: relative;
}
.viewProductDetailsTabs ul.newTabs li.nav-item a.active::after{
  content: '';
  position: absolute;
  left: 45%;
  bottom: -15px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--Secondry);
  clear: both;
}

.viewProductDetailsTabs .tab-content{
  background:var(--Accent);
  padding: 3rem;
}
.ProductDetailsContent{
 
}

.ProductDetailsContent p{
  font-size: 16px;
  font-weight: 400;

}
.ProductDetailsContent h3{
  font-size: 30px;
  font-weight: 600;
  font-family: var(--RubicFont);
}

.ProductIngredientsContent{
  border: 1px solid var(--Primary);
}

.ProductHeader{
  padding: 10px 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--Primary);
}
.ProductHeader h3{
  font-size: 28px;
  font-weight: 600;
  font-family: var(--RubicFont);
  margin: 0;
}
.ProductHeader p{
  font-size: 19px;
  font-weight: 700;
  font-family: var(--RubicFont);
}
.ProductBody table.table.newTable{
  padding: 0;
  margin: 0;
  border: none;
}

.ProductBody table.table.newTable th{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
  padding: 20px;
}
.ProductBody table.table.newTable td{
  font-size: 16px;
  font-weight: 400;
  font-family: var(--RubicFont);
  padding: 20px;
}
.ProductBody table.table.newTable th:last-child,
.ProductBody table.table.newTable td:last-child{
  text-align: right;
}
.ProductBody table.table.newTable tfoot td{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
  padding: 20px;
  text-align: center !important;
}
a.viewMore{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
  padding: 20px 0;
  color: var(--Primary);
}

p.activeIngredients{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
  padding: 20px 0;
  color: var(--Primary);
}
.BenefitsCont h2{
  font-size: 38px;
  font-weight: 700;
  font-family: var(--AleoFont);
  color: var(--Primary);
  margin-top: 5rem;
}
.BenefitsCont h3{
  font-size: 48px;
  font-weight: 700;
  font-family: var(--AleoFont);
  color: var(--Primary);
  margin-top: 2rem;
}

.BenefitsCont ul{
  padding: 10px;
}
.BenefitsCont ul li{
  list-style: disc;
  color: var(--Secondry);
  font-size: 30px;
  font-weight: 500;
  font-family: var(--RubicFont);
  margin-bottom: 25px;
}

.contactBanner{

  padding: 20px;
  height: 400px;
  text-align: center;
  font-size: 38px;
  color: var(--Accent);
  font-weight: 700;
  font-family: var(--AleoFont);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.InnerBannerTitle{
  padding: 20px;
  height: 400px;
  text-align: center;
  font-size: 38px;
  color: var(--Accent);
  font-weight: 700;
  font-family: var(--AleoFont);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
}
.InnerBannerTitle p{
  font-family: var(--RubicFont);
  color: var(--Accent);
  font-weight: 500;
  font-size: 34px;
  margin-top: 20px;
}

.contactContent{
  display: flex;
  background: var(--Accent);
}
.contactContent .left{
  background: var(--Primary);
  padding: 10% 50px;
  width: 25%;
  height: 170vh;
  margin-top: -63px;
  font-family: var(--RubicFont);
}
.contactContent .right{
  padding: 20px;
  width: 75%;
  font-family: var(--RubicFont);
  color: var(--Accent);
}
.contactInfo{
  margin: 20px 0;
}
.contactInfo h3{
  font-size: 34px;
  margin-bottom:30px;
  font-weight: 500;
  color: var(--Accent);
}
.contactInfo h4{
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--Accent);
  margin-top: 40px;
}
.contactInfo p{
  font-size: 19px;
  margin-bottom: 5px;
  font-weight: 500;
  padding: 0;
  color: var(--Accent);
}

.contactForm {
  padding: 5rem 10rem;
}
.contactForm h1{
  font-size: 34px;
  font-weight: 500;
  font-family: var(--RubicFont);
  padding: 0;
  margin: 0 0 20px 0;
  color: var(--Primary);
}
.contactForm h3{
  font-size: 28px;
  font-weight: 600;
  font-family: var(--RubicFont);
  padding: 0;
  margin: 0 0 20px 0;
  color: var(--Primary);
}

.contactSuccess h3{
  font-size: 28px;
  font-weight: 600;
  font-family: var(--RubicFont);
  padding: 0;
  margin: 10rem 0 20px 0;
  color: var(--Primary);
  text-align: center;
}

.contactMessage h5{
  font-size: 28px;
  font-weight: 600;
  font-family: var(--RubicFont);
  padding: 0;
  margin: 20px 0 20px 0;
  color: var(--Primary);
  text-align: center;
}

.member {
  font-family: var(--RubicFont);
}

a.sendButton{
    margin: 20px auto;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--Complementry);
    padding: 10px 20px;
    border-radius: 50px;
    color: var(--Accent);
    margin-top: 10px;
    font-size: 16px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
    border: 2px solid var(--Complementry);
}
a.sendButton:hover{
  background: var(--Accent);
    color: var(--Complementry);
    border: 2px solid var(--Complementry);
}

.contactForm .form-control{
  padding: 15px;
  border: 1px solid var(--Primary);
  color: var(--Primary);
  margin-bottom: 15px;
  background: var(--Accent);
}


/* About Us */

.overviewContent{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  background: var(--AccentTwo);
  margin-top: -40px;
}



.overviewContent .left{
  width: 50%;
  background: var(--Secondry);
  padding: 10% 2%;
  box-shadow: var(--shadow-small);
  position: relative;
  clip-path: polygon(0 0%, 0% 100%, 90% 100%, 100% 3%);
  /* height: 100%; */
  display: flex;
  justify-content: right;
}

.overviewContent .right{
  width: 50%;
  padding: 5% 80px 0 80px;
  
}
.overviewContent .right p{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
}
.overviewContent .right .previewContent h2{
  text-align: left;
  padding: 0;
  margin: 0;
}

.overviewContent ul.newTabs{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 0;
  margin-left: 110px;
}
.overviewContent ul.newTabs li .nav-link{
  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  background: transparent;
  
}
.overviewContent ul.newTabs li.nav-item .nav-link{

  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  padding: 5% 0;
  display: flex;
    align-items: center;
  
  
}
.overviewContent ul.newTabs li.nav-item {
  padding-left: 0;
  padding-right: 0;
  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Primary);
  
  
}
.overviewContent ul.newTabs li:nth-child(even){
  border-image: linear-gradient(-90deg, #F1F1E7 10%, #0C698B 85.5%, #0C698B 45.5%);
  border-bottom: 2px solid transparent;
  border-image-slice: 1;
  width: fit-content;
}
.overviewContent ul.newTabs li:nth-child(odd){
  border-image: linear-gradient(90deg, #F1F1E7 10%, #0C698B 85.5%, #0C698B 45.5%);
  border-bottom: 2px solid transparent;
  border-image-slice: 1;
  width: fit-content;

}
.overviewContent ul.newTabs li .nav-link i{
  font-size: 35px;
  font-weight: normal;
  margin-left: 20px;
  display: none;
}

.overviewContent ul.newTabs li .nav-link.active i{
  font-size: 35px;
  font-weight: normal;
  margin-left: 20px;
  display: block;
  
}

.ourJourney{
  background: var(--Accent);
  padding: 2% 5%;
  display: flex;
  flex-direction: row;

}

.ourJourney .left{
  text-align: center;
  padding: 3%;
  width: 50%;
  }
  .ourJourney .right{
    text-align: center;
    padding: 1%;
    width: 50%;
    }
    .ourJourney .left h2{
      font-size: 48px;
      font-family: var(--RubicFont);
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--Primary);
      margin-top: 10%;
    }
    .ourJourney .left h3{
      font-size: 34px;
      font-family: var(--RubicFont);
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--Primary);
    }
    .ourJourney .left p{
      font-size: 16px;
      font-family: var(--RubicFont);
      font-weight:400;
      margin-bottom: 20px;
      color: var(--Primary);
      text-align: left;
    }

    .ourJourney .right .mapImg{
      width: 80%;
      padding: 3%;
      margin: 0 auto;
      }

    .ourTeam {
      font-size: 48px;
      font-family: var(--RubicFont);
      font-weight: 700;
    }

    .ourProducts{
      background: var(--Accent);
      /* padding: 2% 5%; */
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
    
    }

  .prodctUnits{
    background: #FFFFFF;
    padding: 5% 15%;
  }

  

  .unitsBg{
    background: var(--Primary);
    border-radius: 30px;
    padding: 5%;
    color: var(--Accent);
    display: flex;
    flex-direction: column;
    height: 160px;
    text-align: center;
    
  }
  .unitsBg h2{
    font-family: var(--RubicFont);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .unitsBg p{
    font-size: 19px;
    font-weight: 500;
    font-family: var(--RubicFont);
  }

  .unitsTabs {
    margin: 5% auto;
    
  }
 

  .unitsTabs ul.MSTabs{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 0;
    justify-content: center;
  }
  .unitsTabs ul.MSTabs li.nav-item {
    width: 50%;
    text-align: center;
}
  .unitsTabs ul.MSTabs li .nav-link.active,
  .unitsTabs ul.MSTabs li .nav-link:hover{
    font-family: "Rubik", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      border: none;
      color: var(--Accent);
      background: var(--Secondry);
      padding:1.8rem 1.5rem;
    
  }
  .unitsTabs ul.MSTabs li .nav-link{
    padding: 1.8rem 1.5rem;
    font-family: "Rubik", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    border: none;
    color: var(--Accent);
    text-align: center;
    position: relative;
    background: var(--Primary);
    border-radius: 0;
    
  }

  .unitsTabs ul.MSTabs li .nav-link.active::after{
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--Secondry);
    display: block;
    position: absolute;
    bottom: -12px;
    margin: 0;
    left: 40%;
    padding: 0;
  }

  .prodctUnitsRow{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
  }

  .manufactureBanner{
    padding: 8% 25% 0;
    text-align: center;
    margin-top: 120px;
  }

  .manufactureBanner h2{
    font-family: var(--RubicFont);
    font-size: 34px;
    font-weight: 500;
    color: var(--Primary);
  }
  .manufactureBanner p {
    font-size: 19px;
    font-weight: 400;
    color: var(--Primary);
    margin: 20px;
  }

  .manufactureBanner h3{
    font-family: var(--RubicFont);
    font-size: 24px;
    font-weight: 500;
    color: var(--Primary);
  }
  
.manufactureLinks{
  padding: 0;
  margin: 0;
}

.manufactureLinks ul.MSTabs{
    display: flex;
    border-bottom: 0;
    justify-content: center;
    width: 100%;
    margin: 0;
    flex-wrap: nowrap;
    padding: 0;
}


.manufactureLinks ul.MSTabs li.nav-item {
  width: 100%;
  text-align: center;
}
.manufactureLinks ul.MSTabs li .nav-link.active,
.manufactureLinks ul.MSTabs li .nav-link:hover{
  font-family: "Rubik", sans-serif;
    font-size: 19x;
    font-style: normal;
    font-weight: 500;
    border: none;
    color: var(--Accent);
    background: var(--Secondry);
    padding:2.12rem 1.5rem;
    text-align: center;
    white-space: nowrap;
  
}
.manufactureLinks ul.MSTabs li .nav-link{
  padding: 2.12rem 1.5rem;
  font-family: "Rubik", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  text-align: left;
  position: relative;
  background: var(--Primary);
  border-radius: 0;
  text-align: center;
  white-space: nowrap;
  
  
}

.manufactureLinks ul.MSTabs li .nav-link.active::after{
  content: '';
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid var(--Secondry);
  display: block;
  position: absolute;
  bottom: -12px;
  margin: 0;
  left: 43%;
  padding: 0;
}

.innerPageTabs .scrtabs-tabs-fixed-container{
  height: 90px;
}
.innerPageTabs .scrtabs-tab-scroll-arrow.scrtabs-tab-scroll-arrow-left {
  text-align: right;
  width: 50px; /* affects amount of scrollToActiveTab movement */
  padding-right: 15px;
  font-size: 30px;
  font-weight: 600;
  color: var(--Primary);
}
.innerPageTabs .scrtabs-tab-scroll-arrow.scrtabs-tab-scroll-arrow-right {
  width: 50px; /* affects amount of scrollToActiveTab movement */
  padding-left: 15px;
  font-size: 30px;
  font-weight: 600;
  color: var(--Primary);
}
.innerPageTabs .scrtabs-tab-container {
  height: 64px;
}

.innerPageTabs .scrtabs-tab-scroll-arrow{
  border: none;
  height: 64px;
}
.innerPageTabs .scrtabs-tab-scroll-arrow:hover {
  background-color: transparent;
}
.innerPageTabs .scrtabs-tabs-fixed-container ul.nav-tabs>li {
  flex-grow: 1;
  flex-basis: 0%;
  flex-shrink: 1;
  flex:1
}

.InnerPageTabsContent{
  padding: 2% 0;
  font-family: var(--RubicFont);
}

.InnerPageTabsContent h2{
  font-family: var(--RubicFont);
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding: 2% 0;
}
.InnerPageTabsContent h3{
  font-family: var(--RubicFont);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  padding: 2% 0;
}

.InnerPageTabsContent p{
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  color: var(--Primary);
}
.InnerPageTabsContent p.text2{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--Primary);
}
.InnerPageTabsContent .blendingImageMain{

  margin: 5% auto 10%;
   height: 282px;
    width: 265px;
}

.InnerPageTabsContent .blendingImage{

  margin: 5% auto 10%;
   height: auto;
  width: 80%;
}

.InnerPageTabsContent .CapsulesImage {
  margin: 12% auto 10%;
  height: auto;
  width: 100%;
}
.InnerPageTabsContent .blendingImage2{

  margin: 0% auto 0%;
  height: 350px;
  
}

ul.BlendingList{
  padding: 0;
  margin: 0;

  
}
ul.BlendingList li{
  padding-bottom: 10px;
  margin-bottom: 15px;
  list-style: none;
  border-bottom: 1px dashed var(--Primary);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
}

ul.BlendingList li i{
  font-size: 2.5rem;
}

ul.TabletCoatingList{
  padding: 0;
  margin: 0 0 0 2rem; 
}
ul.TabletCoatingList li{

  list-style: disc;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--Primary);
  margin-bottom: 0.51rem;
}
TabletCoatingList
ul.CapsulesList{
  padding: 0;
  margin: 0;

}
ul.CapsulesList li{
  list-style: disc;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--Primary);
  margin-bottom: 0.51rem;
  margin-left: 1rem;
}

ul.TabletsList{
  padding: 0;
  margin: 0;
}
ul.TabletsList li{
  list-style: disc;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  color: var(--Primary);
  margin-bottom: 0.51rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
ul.BlisterList{
  padding: 0;
  margin: 2rem 0 0 0; 
}
ul.BlisterList li{
  list-style: disc;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  color: var(--Primary);
  margin-bottom: 0.51rem;
  margin-left: 2rem;
  
}

.comingSoon{
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 5rem;
  width: 100%;
}

/* Home Banner Slick Slider */
.slider .slick-slide {
  
  color: white;
  padding: 80px 0 0px;
  font-size: 30px;
  margin-bottom: -11px;
  
}

/* .slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-dots {
  bottom: -30px;
}

.slick-slide:nth-child(odd) {
  background: #e84a69;
} */

.card-img-top {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover; /* Ensures the image covers the area without stretching */
}

.slider .slick-slide{
  padding:0;
}

.progressBarContainer {
  /* position: absolute; */
    bottom: 0;
    width: 94%;
    left: 3%;
}

.progressBarContainer ul li {
  display: block; 
  cursor: pointer;
  float: left;
  position: relative;

}

.progressBarContainer ul li:last-child {
  margin-right: 0;
}

.progressBarContainer ul li span.progressBar {
  width: 100%;
  height: 6px;
  /* background-color: rgba(255, 255, 255, 0.4); */
  background: rgb(12,105,139);
background: linear-gradient(90deg, rgb(2 2 2) 0%, rgb(255 255 255) 58.33%);
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
}

.progressBarContainer ul li span.progressBar .inProgress {
  /* background-color: rgb(0, 0, 0); */
  background: #ffffff;
/* background: linear-gradient(90deg, rgba(12,105,139,1) 0%, rgba(4,5,51,1) 58.33%); */
/* background: linear-gradient(90deg, rgb(2 2 2) 0%, rgb(255 255 255) 58.33%); */
background: linear-gradient(90deg, rgb(5 165 81) 0%, rgb(255 0 1) 89.33%);
  width: 0%;
  height: 6px;
  
}

.table.Producttable{
  padding: 0;
  border: 1px solid var(--Primary);
  margin: 10px 0;
}

.table.Producttable th{
  background: var(--Secondry);
  color: var(--Accent);
  font-size: 19px;
  padding: 15px;
}
.table.Producttable td{
  color: var(--Primary);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between; 
}

.table.Producttable table{
    padding: 0;
    border: 1px solid var(--Primary);
    margin: 0;
    border-left:none;
    border-bottom:none;
    border-top:none;
}

.table.Producttable tr.innerTables{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
}

.innerTables td {
  border: 1px solid var(--Primary);
  border-left: none;
  border-top: none;
  flex-direction: column;
}




/* Product List */

.ProductTopContent{
  padding: 0 2rem 2rem;
  text-align: center;
  margin-top: 120px;
}
.ProductTopContent h2{
  font-size: 38px;
  font-weight: 700;
  font-family: var(--AleoFont);
  color: var(--Primary);
  margin: 0 0 2rem;
}
.ProcutCount{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--RubicFont);
  font-size: 24px;
}
.ProcutCount .ListOfProducts{
  padding: 10px 30px;
    border-right: 1px solid var(--Primary);
    margin-bottom: 30px;
    font-weight: 500;
}
.ProductTopContent p{
  font-size: 16px;
  font-family: var(--RubicFont);
  font-weight: 400;
}

.ProductSlider {
  padding: 0;
  margin: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width:992px) {
  .ProductSlider {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.ProductSlider .productItem{
  list-style: none;
    background: var(--Primary);
    font-family: -webkit-body;
    border-radius: 20px;
    padding: 20px;
    box-shadow: inset 1px 2px 11px 4px #0000002e;
    width: 95% !important;
    margin-bottom: 20px;
    min-height: 180px;
}

.ProductSlider a.productItem{
  color: var(--Accent);

}
.ProductSlider .productItem .ProductIcon{
  font-size: 60px;
  padding: 0;
  margin-bottom: 15px;
}
.ProductSlider .productItem h4{
  font-size: 24px;
  font-family: var(--RubicFont);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}


.product-slider .slick-arrow {
  width: 50px;
  height: 50px;
  border: 0;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
  /* height: 18px; */
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  bottom: -20px;
    margin: -50px auto;
}

.product-slider .slick-next::after{
  content: "\E82F";
  font-family: "pharmalinefonts";
  font-size: 50px;
  font-weight: normal;
  margin-right: -50px;
}
.product-slider .slick-prev::after{
  content: "\E82E";
  font-family: "pharmalinefonts";
  font-size: 50px;
  font-weight: normal;
  margin-left: -50px;
}

  /**
 *  Progress Bar
 */
 .ProductProgress {
  display: block;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--AccentTwo);
  background-image: linear-gradient(to right, var(--Primary), var(--Primary));
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
  margin-top: 100px;

}

/**
 *  Demo Styles from the Slick Slider
 *  website
 */



.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.manufacturingRow .left h3,
.PackagingServicesRow .right h3{
  /* margin: 1% 0 0%; */
}


.ProductDetailsText{
  font-size: 16px;
  font-weight: 500;
  font-family: var(--RubicFont);
}
.ProductDetailsText:first-child{
  /* width: 85%; */
}
.ProductDetailsText:last-child{
  /* width: 15%; */
}
.ProductDetailsInfo{
  font-size: 16px;
  font-weight: 400;
  font-family: var(--RubicFont);
  margin-bottom: 20px;
}


/* Product List Dropdown */

.select-menu {
  max-width: 330px;
  /* margin: 50px auto; */
  width: 330px;
}
.select-menu .select-btn {
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: var(--Secondry);
    padding: 10px 25px;
    font-family: var(--RubicFont);
    font-size: 19px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: var(--Accent);
    gap: 10px;

}
.select-menu .options {
  position: absolute;
  width: 330px;
  overflow-y: auto;
  max-height: 295px;
  padding: 0;
  margin-top: 10px;
  border-radius: 8px;
  background: var(--Accent);;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-duration: 0.35s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  font-weight: 500;
  font-family: var(--RubicFont);
}
.select-menu .options .option {
  display: flex;
  height: 55px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 0px;
  align-items: center;
  background: var(--Primary);
}
.select-menu .options .option:hover {
  background: var(--Accent);
  color: var(--Primary);
}
.select-menu .options .option:hover .option-text{
  color: var(--Primary);
}
.select-menu .options .option i {
  font-size: 15px;
  margin-right: 12px;
}
.select-menu .options .option .option-text {
  font-size: 16px;
  color: var(--Accent);
}

.select-btn i {
  font-size: 15px;
  transition: 0.3s;
}

.select-menu.active .select-btn i {
  transform: rotate(-180deg);
  margin-right: -10px; 
}
.select-menu.active .options {
  display: block;
  opacity: 0;
  z-index: 10;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
}


@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}

@media (max-width:3840px) {
  .overviewContent .right{
    width: 50%;
    padding: 7% 10%;
  }

  .broucher-div {
    margin-top: 100px;
    height: 900px;
  }
}


@media (min-width:2024px){
  .homeBanner .caption {
    background: var(--Primary);
    padding: 30px;
    width: 50%;
    color: var(--Accent);
    position: absolute;
    top: 20%;
    left: 0;
    margin-top: 180px;
}
  
}

@media (max-width:1980px) {
  
.homeBanner .caption{
  background: var(--Primary);
        padding: 30px;
        width: 50%;
        color: var(--Accent);
        position: absolute;
        top: 20%;
        left: 0;
        margin-top: 15%;
}
.homeBanner .caption p{
    border-left: 8px solid var(--Accent);
    font-size: 31px;
    font-weight: 600;
    margin-right: 20px;
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 43px;
    margin-bottom: 20px;

}

.overviewContent .right {
  width: 50%;
  padding: 5% 10% 2% 3%;
}
  
}

@media only screen  and (max-width: 1780px)  {
  /* .homeBanner .caption{
    left: 10%;
  } */
}


@media screen and (min-width: 1440px) {

  .companyOverview .leftbg {
    /* width: 100%;
    padding: 15% 10% 15% 24%; */
    width: 100%;
    padding: 15% 0 15% 0;
    display: flex;
    justify-content: end;

}
.leftContent{
  width: 600px;
  display: flex;
  justify-content: right; 
}
.rightContent{
  width: 560px;
    margin: 0 80px 0 80px;
}

.overviewContent ul.newTabs{
    margin: 0;
    width: 600px;
    display: flex;
    justify-content: flex-end;
}
.companyOverview .right {
  width: 50%;
  padding: 5% 0;
}
.manufacturingRow .right {
  padding: 5% 0;
}
.PackagingServicesRow .left {
  padding: 5% 3% 3% 0;
  display: flex;
  justify-content: right;
}
.ourProducts .left {
  width: 42%;
}
.ourProducts .leftbg {
  width: 100%;
  /* padding: 25% 20% 25% 24%; */
  display: flex;
  justify-content: right;
}
.ourProducts .leftbg .leftContent{
  width: 350px;
}
.ourProducts .right {
  width: 45%;
  padding: 5% 5% 5% 2%;
}
.prodctUnitsRow{
  margin: 0% !important;
}
.overviewContent .right {
  width: 50%;
  padding: 5% 10% 2% 3%;
}
.ourJourney .left {
  text-align: left;
  padding: 3% 3% 3% 10%;
  width: 50%;
}
.navScrolled {
  position: fixed;
  top: 100px;
  z-index: 1;
  /* width: 63% !important;
  left: 18%; */
  /* width: 74% !important; */

}

.broucher-div {
  margin-top: 100px;
  height: 600px;
}

}

@media(max-width:1440px){
  .InnerPageBody {
    padding: 100px 0 10%;
    background: #FFFFFF;
}
  .prodctUnits {
      background: #FFFFFF;
      padding: 5% 8%;
  }
  .navScrolled {
    position: fixed;
    top: 100px;
    z-index: 1;
    /* width: 84% !important;
    left: 9%; */
  
  }
  .ourJourney .left{
    text-align: center;
    padding: 3%;
    width: 50%;
    }
  .overviewContent .right {
    width: 50%;
    padding: 4% 10% 2% 3%;
  }
  
  .companyOverview .leftbg {
    width: 85%;
    padding: 10%;
    align-content: center;
  }
  .overviewContent ul.newTabs{
    margin-left: 0;
  }
 
  .bannerBottomBox ul {
    width: 100%;
  }
  .overviewContent .left {
    width: 50%;
    background: var(--Secondry);
    padding: 10% 5% 10% 5%;
    box-shadow: var(--shadow-small);
    position: relative;
    /* clip-path: polygon(0 0%, 0% 100%, 90% 100%, 100% 5%); */
    /* height: 100%; */
}
.manufactureBanner {
  padding: 8% 23% 0;
}

}


@media (max-width:1299px){
a.navSearch.search-toggle::after,
.navSearch.search-toggle.active::after {
  font-size: 30px;
  margin-top: 0;
  margin-right: 10px;
}
.navContent {
  width: 100%;
  margin: 0 auto;
}
}


@media(max-width:1249px){
  .custom-arrow{
    display: none !important;
  }
  .InnerPageTabsContent {
    padding: 2%;
    font-family: var(--RubicFont);
}
  .InnerPageBody {
    
    background: #FFFFFF;
}
  a.navSearch.search-toggle::after,
  .navSearch.search-toggle.active::after {
    font-size: 3rem;
    margin-top: -10px;
    margin-right: 10px;
}
  .navScrolled {
    
    top: 100px;
    z-index: 1;
    width: 100% !important;
    left: 0;
}
  .homeBanner .caption{
    left:3%;
  }
  .AboutCol ul li{
    
    width: 100%;
  }
  .ourProducts .left::after{
    border-bottom: 100px solid #d9d9d9;
    border-left: 49vw solid transparent;
    border-right: 49vw solid transparent;
  }
  /* .companyOverview {
    background: var(--AccentTwo);
    display: flex;
    flex-direction: column;
  } */
  .manufacturingRow {
    background-color: var(--Accent);
    display: flex;
    flex-direction: column;
  }
  .PackagingServicesRow {
    background-color: var(--Primary);
    display: flex;
    flex-direction: column-reverse;
  }
  .ourProducts {
    background: var(--AccentTwo);
    display: flex;
    flex-direction: column;
  }
  
  .manufacturingRow .left {
    background: var(--Secondry);
    padding: 3% 0 0%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.manufacturingRow .right {
  padding: 3% 3% 5%;
  text-align: left;
  width: 100%;
}

.ProductSlider .productItem{
  min-height: 170px;
}
a.navSearch {
  font-size: 48px;
}
.homeBanner .caption p {
  border-left: 8px solid var(--Accent);
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 38px;
  margin-bottom: 20px;
}

.menuProductList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 30px auto;
  padding: 0;

}
.menuProductList li a .productIcon {
 
  padding: 10px 10px;
  font-size: 70px;
  width: 30%;
  justify-content: center;
  align-items: center;
}
.qualityAssurance .data{
width: auto;
}
.QAimage{
  width: 30%;
}
.AboutCol {
  padding: 5% 0;
  color: var(--Primary);
}
.AboutCol ul li {
  padding: 20px 0;
}

.manufacturingMenu {
  margin: 30px 0 0;
  padding: 0 20px;
}
.CatlogImage {
  margin: 20px auto;
  width: 80%;
}
.companyOverview{
background: var(--AccentTwo);
display: flex;
flex-direction: column;
}
.companyOverview .left{

width: 100%;
filter: drop-shadow(-1px 1px 2px rgba(50, 50, 0, 0.5))
}
.companyOverview .leftbg{
  width: 100%;
  padding: 15% 5% 0;
  background: var(--Accent);
  box-shadow: var(--shadow-small);
  position: relative;
  clip-path: polygon(0 0%, 0% 100%,100% 100%, 100% 0%);
  height: auto;

}

.companyOverview .right{
width: 100%;
padding: 5%;

}
.companyOverview ul.newTabs{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
border-bottom: 0;
margin: 0 5%;
width: 90%;
}
.companyOverview ul.newTabs li .nav-link{
font-family: "Rubik", sans-serif;
font-size: 34px;
font-style: normal;
font-weight: 500;
border: none;
color: var(--Primary);
background: transparent;

}
.companyOverview ul.newTabs li.nav-item .nav-link{

font-family: "Rubik", sans-serif;
font-size: 24px;
padding: 1.11rem 1.5rem;
font-style: normal;
font-weight: 500;
border: none;
color: var(--Primary);


}
.companyOverview ul.newTabs li.nav-item {
padding-left: 0;
padding-right: 0;
font-family: "Rubik", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 500;
border: none;
color: var(--Primary); 
width: 100%;
text-align: center;
}
.companyOverview ul.newTabs li .nav-link{
padding: 1.5rem;
font-size: 32px;
}
.companyOverview ul.newTabs li.nav-item:last-child{
border: none;
}
.companyOverview ul.newTabs li .nav-link.active{
background: var(--Primary) !important;
font-size: 24px;
padding: 1.11rem 1.5rem !important;
color: var(--Accent);
position: relative;
border-radius: 0 !important;
}
.companyOverview ul.newTabs li .nav-link.active::after{
content: '';
width: 0;
height: 0;
border-left: 15px solid transparent;
border-right: 15px solid transparent;
border-top: 15px solid var(--Primary);
position: absolute;
bottom: -12px;
margin: 0;
left: 50%;
padding: 0;
}
.previewContent p{
text-align: left;
}
.previewContent {
font-size: 20px;
text-align: center;
}

/* Manufacturing */

.manufacturingRow{
background-color: var(--Accent);
display: flex;
flex-direction: column;
}
.manufacturingRow .left{
background: var(--Secondry);
padding: 3% 0 0%;
display: flex;
align-items: center;
flex-direction: column;
width: 100%;
}
.manufacturingRow .right{
padding: 3% 3% 5%;
text-align: left;
width: 100%;
}
.manufacturingRow .left h3{
color: var(--Accent);
font-size: 34px;
font-weight: 500;
padding: 0 0 3rem;
}
.manufacturingRow .left ul{
padding: 0;
max-width: 100%;
width: 100%;
}
.manufacturingRow ul.MSTabs{
display: flex;
flex-direction: row;
border-bottom: 0;
text-align: center;
justify-content: center;

}
.manufacturingRow ul.MSTabs li .nav-link.active,
.manufacturingRow ul.MSTabs li .nav-link:hover{
font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  border: none;
  color: var(--Accent);
  background: var(--Primary);
  padding:1.5rem 1.5rem;
  position: relative;
  text-align: center;

}
.manufacturingRow ul.MSTabs li .nav-link{
padding: 1.5rem 1.5rem;
font-family: "Rubik", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 500;
border: none;
color: var(--Accent);
text-align: center;
position: relative;

}

/* start */
.custom-arrow {
  font-family: 'pharmalinefonts'; /* Use the PharmaLine font */
  font-size: 16px;          /* Adjust size as needed */
  margin-right: 8px;        /* Add spacing after the arrow */
  color: #000;              /* Set the desired arrow color */
  display: inline-block;    /* Ensure proper spacing */
}

/* ends */
.manufacturingRow ul.MSTabs li .nav-link::after{
display: none;
}
.manufacturingRow ul.MSTabs li a.nav-link.active::after{
content: '';
width: 0;
height: 0;
border-left: 15px solid transparent;
border-right: 15px solid transparent;
border-top: 15px solid var(--Primary);
display: block;
position: absolute;
bottom: -12px;
margin: 0;
left: 40%;
padding: 0;
}

.manufacturingContent{

text-align: center;
margin-bottom: 2rem;
}
.manufacturingContent h2{

font-size: 32px;
padding: 2% 0px 1%;
}
.manufacturingContent h4{
  
font-size: 20px;

}
.manufacturingContent p{

font-size: 18px;

}

.prodctUnits {
  background: #FFFFFF;
  padding: 5% 5%;
}
.manufactureLinks ul.MSTabs li .nav-link.active::after{
  left: 50%;
}

/* PackagingServicesContent */

.PackagingServicesRow{
background-color: var(--Primary);
display: flex;
flex-direction: column-reverse;

}
.PackagingServicesRow .left{
background: var(--Accent);
padding: 3% 3% 8%;
text-align: center;
width: 100%;
box-shadow: var(--shadow-small);
}
.PackagingServicesRow .right{

padding: 3% 0 0%;
text-align: center;
width: 100%;
box-shadow: var(--shadow-small);
}
.PackagingServicesRow .right h3{
color: var(--Accent);
font-size: 34px;
font-weight: 500;
padding: 0 0 3rem;
}
.PackagingServicesRow .right ul{
padding: 0;
max-width: 100%;
}
.PackagingServicesRow ul.MSTabs{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
border-bottom: 0;
justify-content: center;
}
.PackagingServicesRow ul.MSTabs li .nav-link.active,
.PackagingServicesRow ul.MSTabs li .nav-link:hover{
font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  border: none;
  color: var(--Accent);
  background: var(--Secondry);
  padding:1.5rem 1.5rem;
  position: relative;
  text-align: center;

}

.PackagingServicesRow ul.MSTabs li .nav-link{
padding: 1.5rem 1.5rem;
font-family: "Rubik", sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 500;
border: none;
color: var(--Accent);
text-align: center;
position: relative;

}
.PackagingServicesRow ul.MSTabs li .nav-link i{
display: none;
}
.PackagingServicesRow ul.MSTabs li a.nav-link.active::after{
content: '';
width: 0;
height: 0;
border-left: 15px solid transparent;
border-right: 15px solid transparent;
border-top: 15px solid var(--Secondry);
display: block;
position: absolute;
bottom: -12px;
margin: 0;
left: 50%;
padding: 0;
}

.PackagingServicesContent{
padding: 0;
margin: 0;
color: var(--Primary);
font-family: "Rubik", sans-serif;
}
.PackagingServicesContent h2{

font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 42px;
padding: 5% 0;
}
.PackagingServicesContent h4{
  
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 36px;
margin-bottom: 1.1rem;
}
.PackagingServicesContent p{

font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 33px;
}

.PackagingServicesContent p.imagePosition {
  display: flex;
  justify-content: start;
}


/* ourProducts */
.ourProducts{
  background: var(--AccentTwo);
  display: flex;
  flex-direction: column;
  }
  .ourProducts .left{
  width: 100%;
  box-sizing: content-box;
  padding-top: 15px;
  position: relative;
  background: var(--Complementry);
  color: var(--AccentTwo);
  font-size: 11px;
  letter-spacing: 0.2em;
  text-align: center;
  
  }
  
  .ourProducts .left::after{
  content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 0;
      /* border-bottom: 100px solid var(--AccentTwo);
        border-left: 400px solid transparent;
        border-right: 400px solid transparent; */
  }
  
  .ourProducts .leftbg{
  
   width: 100%;
    padding: 10% 10% 15%;
    background: var(--Complementry);
    box-shadow: var(--shadow-small);
    position: relative;
    clip-path: polygon(0 0%, 0% 100%, 85% 100%, 100% 0%);
    height: 100%;
  }
  .ourProducts .leftbg {
  font-family: "Aleo", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: var(--Accent);
  }
  .ourProducts .right{
  width: 100%;
  padding: 5%;
  }
  ul.productList{
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  }
  ul.productList li{
  list-style: none;
    padding: 10px 0rem;
    display: flex;
    gap: 15px;
    width: 190px;
    text-align: center;
    justify-content: center;
  }
  ul.productList li a{
  color: var(--Primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  }
  ul.productList li a .icon{
  background: var(--Primary);
  width: 100px;
  height: 100px;
  color: var(--Accent);
  font-size: 4.5rem;
  padding: 0;
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  }
  ul.productList li a .icon:hover{
  background: var(--Complementry);
  }
  ul.productList li a p{ 
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 10px;
  } 

ul.FTLinks {
padding: 0;
margin: 0;
display: flex;
flex-direction: column;
flex-wrap: wrap;
gap: 10px;
}
ul.FTLinks li {
padding: 0;

}
ul.FTLinks li a{
font-size: 16px;
white-space: nowrap;
}

ul.FTLinks li a:hover{
  color: var(--Primary);
  background: var(--Accent);
}

.manufacturingContent h4,
.manufacturingContent p,
.PackagingServicesContent h4,
.PackagingServicesContent p {
  text-align: center;
}

.companyOverview .leftbg {
width: 100%;
padding: 8% 0 0;
}


/* AboutPage
------------ */
.overviewContent{
display: flex;
flex-direction: column;
margin: 30px 0 0 0;
height: auto;
}
.overviewContent .left{
width: 100%;
clip-path: inherit;
padding: 10% 10%  0;
background: var(--Accent);
display: flex;
align-items: center;
justify-content: center;
width: 100%;
}

.overviewContent ul.newTabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 0;
  justify-content: center;
}
.overviewContent ul.newTabs li:nth-child(odd) {
  border-image: none;
  border-bottom: 2px solid transparent;
  border-image-slice: 1;
  

}

.overviewContent ul.newTabs li{
  background: var(--Accent);
  color: var(--Primary);
}


.overviewContent ul.newTabs li .nav-link.active i{
display: none;
}
.overviewContent ul.newTabs li:nth-child(even){
/* width: 50%; */
border-image: none;

}
.overviewContent .right {
width: 100%;
padding: 4% 3%;
text-align: left;
}
.ourJourney {
padding: 2% 0;
display: flex;
flex-direction: column;
}
.ourJourney .left {
text-align: center;
padding: 3% 10%;
}
.overviewContent ul.newTabs li.nav-item .nav-link.active{
  position: relative;
  background: var(--Secondry);
  padding: 1.5rem 1rem;
  color: var(--Accent);
  display: flex;
  align-items: center;
  justify-content: center;
}
.overviewContent ul.newTabs li.nav-item .nav-link{
font-size: 32px;
font-weight: 500;
color: var(--Primary);
text-align: center;
padding: 1.5rem 1rem;
display: flex;
  align-items: center;
  justify-content: center;
}

.InnerBannerTitle p {

  font-weight: 500;
  font-size: 30px;
  margin: 2% 15%;
}
.ourJourney .left,
.ourJourney .right{
  width: 100%;
}
.prodctUnitsRow {
  margin: 0 !important;
}

.prodctUnits .container{
  max-width: 100%;
}
.overviewContent ul.newTabs li.nav-item .nav-link.active::after{
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--Secondry);
    display: block;
    position: absolute;
    bottom: -13px;
    margin: 0;
    left: 50%;
    padding: 0;
}



  .contactForm {
    padding: 2rem;
}
.contactContent {
  display: flex;
  background: var(--Accent);
  flex-direction: column-reverse;
}
.contactForm {
  padding: 1rem 3rem;
}
.contactContent .left {
  background: var(--Primary);
  padding: 0% 50px;
  width: 100%;
  height: 100%;
  margin-top: 0;
  gap: 30px;
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(3, 1fr);
}
.contactContent .right {
  padding: 0;
  width: 100%;
  font-family: var(--RubicFont);
  color: var(--Accent);
}

.footer-top {
  top: 20px;
}
.unitsTabs {
  margin: 5% auto 15%;
}
.footer-top {
  position: absolute;
  background: var(--Primary);
  padding: 2rem;
  font-family: var(--RubicFont);
  clip-path: polygon(200% 0, 100% 90%, 0 90%);
  height: 202px;
  margin-top: -202px;
  top: 22px;
  width: 100%;
}


.previewContent.scrollable,
.overviewContent .slimScrollDiv{
  height: auto !important;

}

.overviewContent ul.newTabs {
  margin-left: 50px;
}

.ourProducts .leftbg .leftContent {
  width: 60%;
  margin: 0 auto;
}
}

@media(max-width:1024px) {
  .ourProducts .leftbg .leftContent {
    width: 100%;
}
    
  .homeBanner, .homeBanner.slide1, .homeBanner.slide2, .homeBanner.slide3 {
    height: 495px;
  }
  .homeBanner .caption{
    width: 60%;
    background: #040533eb;
  }
  .homeBanner .caption p{
    font-size: 16px;
    line-height: 30px;
  }
  ul.productList li{
    width: 150px;
  }
  .InnerPageBody {
    padding: 20% 0 10%;
    background: #FFFFFF;
}
  
  .prodctUnits.container {
    max-width: 860px !important;
}
  .manufactureLinks ul.MSTabs li .nav-link,
  .manufactureLinks ul.MSTabs li .nav-link.active,
  .manufactureLinks ul.MSTabs li .nav-link:hover {
    padding: 1.12rem 0.5rem;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
  }
  a.navSearch{
    width: 40px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
  }
  /* .companyOverview .leftbg {
    width: 100%;
    padding: 25% 15%;
  } */
  .previewContent h2 {
    font-size: 28px;
    line-height: 35px;
    margin: 0 15% 1rem;
    text-align: center;
    position: relative;
  }
  .ourProducts .leftbg {
    padding: 10% 12% 15%;
  }

  
  .footer-top {
    margin-top: -200px;
  }
  .unitsTabs {
    margin: 5% auto 10%;
}
.ProductSlider .productItem h4 {
  font-size: 16px;
}
  ul.gmpList {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: nowrap;
}
ul.ProductList {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.navScrolled {
  top: 100px;
}
.manufactureBanner {
    padding: 8% 10% 0;
}
.overviewContent .left{
  display: flex;
    justify-content: center;
}
.prodctUnitsRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-content: center;
}

.footerRow.container .row{
  display: grid ;
      align-items: baseline;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 55px;
}


.footerRow.container .row .col-lg-3{
  width: 100%;
}
 
.footerRow.container{
  max-width: 94%;
}
}


@media(min-width:992px) {
  
  .footer {
  
    padding: 5rem 0rem;
    
}
}
@media(max-width:992px) {
  
  .prodctUnits {
    background: #FFFFFF;
    padding: 5% 0%;
  }
  .InnerPageTabsContent .blendingImage {
    margin: 5% auto 10%;
    height: auto;
    width: 100%;
}
.InnerPageTabsContent .blendingImage2 {
  margin: 0% auto 0%;
  height: auto;
  width: 100%;
}
.overviewContent .right p {
  font-size: 25px;
  font-weight: 500;
  font-family: var(--RubicFont);
}
.ourProducts .leftbg {
  font-family: "Aleo", serif;
  font-size: 40px;
}
.ourProducts .leftbg {
  padding: 12% 15% 25% 15%;
}
.contactContent .left {
  background: var(--Primary);
  padding: 0% 50px;
  width: 100%;
  height: 100%;
  margin-top: 0;
  gap: 30px;
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(2, 1fr);
}
}


@media (max-width: 991px) {
  
}

@media (max-width:920px){
  /* .ourProducts .left::after {
  
    border-bottom: 100px solid var(--AccentTwo);
    border-left: 460px solid transparent;
    border-right: 460px solid transparent;
  } */
}

@media(min-width:768px){
  
  /* .prodctUnits {
    background: #FFFFFF;
    padding: 5% 20%;
  } */
  .prodctUnits .prodctUnitscontainer{
    max-width: 100%;
}
/* .ourProducts .left::after {
  
  border-bottom: 100px solid var(--AccentTwo);
  border-left: 490px solid transparent;
  border-right: 490px solid transparent;
} */
/* .manufacturingRow .left h3,
.PackagingServicesRow .right h3{
  margin: 5% 0 3%;
} */


}



@media(max-width:768px){
  .table.Producttable td {
    flex-direction: column;
}
  .prodctUnits .prodctUnitscontainer{
    max-width: 100%;
}

.manufacturingRow .left h3,
.PackagingServicesRow .right h3{
  margin: 5% 0 3%;
}
.manufacturingRow ul.MSTabs li .nav-link,
.manufacturingRow ul.MSTabs li .nav-link.active,
 .manufacturingRow ul.MSTabs li .nav-link:hover,
 .PackagingServicesRow ul.MSTabs li .nav-link.active, 
 .PackagingServicesRow ul.MSTabs li .nav-link:hover,
 .PackagingServicesRow ul.MSTabs li .nav-link {
  font-size: 19px;
}
.overviewContent ul.newTabs {
  margin-left: 0;
}
.overviewContent ul.newTabs li:nth-child(even),
.overviewContent ul.newTabs li:nth-child(odd){
  width: auto;
}
ul.ProductList {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.home_banner .bx-wrapper .bx-viewport p{
  font-size: 25px;
}
.footerRow.container{
  max-width: 600px;
}
}


@media(max-width:767px) {
  .manufacturingContent h2 {
    font-size: 32px;
    padding: 5% 0px 1%;
    text-align: center;
}
  .menu {
    width: 95%;
  }
  .menuSVG {
    height: 62px;
    width: 62px;
}
  .AboutCol {
    padding: 5% 0;
    /* color: var(--Accent); */
  }
  .AboutCol ul li {
    padding: 30px 0;
  }
  .QAimage{
    width: 30%;
  }
  .AboutCol ul li a {
   
    font-size: 19px;
    font-weight: 500;
  }
  .homeBanner .caption{
    width:80%;
  }
  ul.productList li{
    width: 180px;
  }
.menuProductList li {
  
  border-bottom: 1px solid var(--Accent);
}
/* .menuProductList li a{
  color: var(--Accent);
} */
.menuProductList li {
  list-style: none;
  padding: 0;
  margin: 10px;
  display: flex;
  border-bottom: 1px solid var(--Accent);
}
.manufacturingMenu ul.manufacturingTabsMenu li a {
  padding: 26px 20px;
  font-size: 19px;
  color: var(--Primary);
  border-bottom: 1px solid var(--Primary);
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-bottom: 0;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top: none;
  border-left: none;
  border-right: none;
}
.manufactureBanner {
  padding: 8% 5% 0;
}

.ProductSlider {
  padding: 0;
  margin: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.ProductSlider .productItem{
  display: inline-block;
}
.ProductProgress{
  display: none;
}
.ProductSlider .productItem .ProductIcon {
  font-size: 70px;
}
.row.prodctUnitsRow .col-6{
   margin-bottom: 20px;
}
.overviewContent .left{
  padding: 10% 0%  0;
}
.overviewContent ul.newTabs {
  margin: 0 4%;
}

.overviewContent ul.newTabs li.nav-item .nav-link,
.overviewContent ul.newTabs li.nav-item .nav-link.active {
  font-size: 25px;
  text-align: center;
  padding: 1.5rem 1.5rem;
  
}
ul.ProductList {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.footer-top {
  
  display: none;
}
.topHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  gap: 25px;
  margin: 50px 0;
}
.topHeader a.sortByBtn {
  width: 35%;
}
.topHeader .left {
  width: 100%;
}
.topHeader .right {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.topHeader a.sleepSupport {
  background: var(--Secondry);
  padding: 10px 25px;
  font-family: var(--RubicFont);
  font-size: 19px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--Accent);
  gap: 10px;
  width: 70%;
  justify-content: space-between;
}

.manufactureLinks ul.MSTabs,
  .manufacturingRow ul.MSTabs,
  .PackagingServicesRow ul.MSTabs
  /* .companyOverview ul.newTabs, */
  /* .overviewContent ul.newTabs */
  {
    display: block;
  }
  
  /* Hide all tabs except the active one */
  .manufactureLinks ul.MSTabs.nav-tabs.mobile-tabs .nav-item,
  .manufacturingRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item,
  .PackagingServicesRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item
  /* .companyOverview ul.newTabs.nav-tabs.mobile-tabs .nav-item, */
  /* .overviewContent ul.newTabs.nav-tabs.mobile-tabs .nav-item */
   {
    display: block;
  }

  /* Show the active tab */
  .manufactureLinks ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active,
  .manufacturingRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active,
  .PackagingServicesRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active
  /* .companyOverview ul.newTabs.nav-tabs.mobile-tabs .nav-item.active, */
  /* .overviewContent ul.newTabs.nav-tabs.mobile-tabs .nav-item.active  */
  {
    display: block;

  }

  /* Ensure the active tab looks like a dropdown */
  .manufactureLinks ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active > a:before,
  .manufacturingRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active > a:before,
  .PackagingServicesRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.active > a:before
  /* .companyOverview ul.newTabs.nav-tabs.mobile-tabs .nav-item.active > a:before, */
  /* .overviewContent ul.newTabs.nav-tabs.mobile-tabs .nav-item.active > a:before  */
  {
    content: "\E800" !important ;/* Customize this arrow icon as per your font icons */
    position: absolute;
    right: 15px;
    font-family: "pharmalinefonts";
    font-size: 19px;
  }

  /* Show tabs when dropdown is opened */
  .manufactureLinks ul.MSTabs.nav-tabs.mobile-tabs .nav-item.d-block,
  .manufacturingRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.d-block,
  .PackagingServicesRow ul.MSTabs.nav-tabs.mobile-tabs .nav-item.d-block
  /* .companyOverview ul.newTabs.nav-tabs.mobile-tabs .nav-item.d-block, */
  /* .overviewContent ul.newTabs.nav-tabs.mobile-tabs .nav-item.d-block  */
  {
    display: block;
  }
  .submenu.container {
    max-width:100%;
  }
  .table.Producttable tr.innerTables {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
}

@media(max-width:599px){
  .homeBanner, .homeBanner.slide1, .homeBanner.slide2, .homeBanner.slide3 {
    height: 495px;
    background: var(--Primary);
}
  ul.ProductList li.ProductItems .item .pic .ProductOverlay{
  clip-path: none;
}
ul.ProductList li.ProductItems .text{
  font-size: 17px;
  font-weight: 400;
}

  .ProductSlider {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
  .ProductSlider a.productItem {
    min-height: 100px;
    padding: 0 10px;
    margin: 0;
    width: 100% !important;
  }
  .ProductSlider a.productItem  {
    display: flex;
    align-items: center;
  }
  .ProductSlider .productItem .ProductIcon {
    font-size: 70px;
    margin: 0;
  }
  .ProductSlider .productItem h4 {
    font-size: 19px;
    margin: 0;
    padding: 0;
    max-width: 350px;
  }
  .productItem .ProductIcon i .ProductSVG {
    height: 82px;
    width: 82px;
    filter: invert(1);
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }

  

  ul.ProductList {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}
  ul.ProductList li.ProductItems .item {
    display: flex;
    width: 100%;
    height: auto;
    background: var(--Accent);
  }
  ul.ProductList li.ProductItems .item .pic{
    width: 50%;
    background: var(--Accent);
  }
  ul.ProductList li.ProductItems .ItemContent {
    padding: 50px 30px;
    font-family: var(--RubicFont);
    width: 50%;
}
ul.ProductList li.ProductItems .item .pic img{
  width: 188px;
    text-align: revert;
    margin: 0 auto;
    background: var(--Accent);
}
  .navbar-left, .navbar-right {
    flex: 0 0 auto;
    width: 160px;
    margin-left: 15px;
}
  .mobile{
    display: none !important;
  }
  .mobileSm{
    display: block;
  }
  ul.BlendingList {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}
#header.show .search-box {
  max-height: 100vh;
  height: 100vh;
}
/* HomePage */


.manufacturingRow ul.MSTabs li .nav-link,
.PackagingServicesRow ul.MSTabs li .nav-link{
  width: 100%;
  text-align: center;
}
.manufacturingRow .left ul{
  width: 100%;
}
.manufacturingRow .left h3{
  text-align: center;
  font-size: 28px;
}
.progressBarContainer ul li{
  display: none;
}
.homeBanner .caption{
  width: 100%;
  top:25%;
}
.homeBanner {
  height: 500px;
}
.slider .slick-slide {
  margin-bottom: -11px;
  
}


.ourProducts .left::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 0;
  /* border-bottom: 30px solid var(--AccentTwo);
  border-left: 270px solid transparent;
  border-right: 160px solid transparent; */
}
ul.gmpList.FirstRow{
  display: flex ;
  flex-direction: row;
  flex-wrap: nowrap;
}

ul.gmpList {
  padding: 0;
  margin: 0;
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}
ul.productList li {
  list-style: none;
  padding: 10px 0rem;
  display: flex;
  gap: 15px;
  width: 180px;
  text-align: center;
  justify-content: center;
}
ul.productList li a .icon {
  
  width: 150px;
  height: 150px;
  font-size: 6.5rem;
}

ul.productList li a .icon .ProductSVG {
  height: 100px;
  width: 100px;
}
ul.gmpList li img {
  width: 100%;
}
.qualityAssurance {
  background: var(--Accent);
  padding: 0rem 1rem;
  font-family: var(--RubicFont);
}

/* Contact us */

.contactContent {
  display: flex;
  background: var(--Accent);
  flex-direction: column-reverse;
}
.contactForm,
.contactSuccess {
  padding: 1rem 3rem 10rem;
  
}
.contactContent .left {
  background: var(--Primary);
  padding: 10% 50px;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.contactContent .right {
  padding: 0;
  width: 100%;
  font-family: var(--RubicFont);
  color: var(--Accent);
}

/* About US */
.overviewContent {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: auto;
}
.overviewContent .left {
  width: 100%;
  clip-path: inherit;
}
.overviewContent .right {
  width: 94%;
  padding: 5% 3%;
}

.ourJourney {
  display: flex;
  flex-direction: column;
}
.unitsTabs {
  margin: 5% auto 25%;
  width: 100%;
}
.prodctUnits {
  background: #FFFFFF;
  padding: 5% 0%;
}

.unitsTabs ul.MSTabs li .nav-link,
.unitsTabs ul.MSTabs li .nav-link.active, 
.unitsTabs ul.MSTabs li .nav-link:hover{
  font-size: 16px;
  padding: 1.11rem 3rem;
}
.ourProducts .leftbg {
  font-size: 28px;
  font-weight: 600;
  padding-top: 10%;
  padding-bottom: 40%;
}
.slider{
  margin-top: 100px;
}

a.navSearch {
  font-size: 48px;
  position: absolute;
  top: 20px;
  right: 10px;
  display: none;
}

a.navSearch.search-toggle::after {
  width: 52px;
  height: 52px;
  background-color: var(--Primary);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Accent);
  position: absolute;
  top: 105px;
  z-index: 111;
  right: 10px;
}
.BottomBtn {
  position: fixed;
  bottom: 80px;
  right: 30px;
  display: none;
}
a.BottomGetQuote {
  background: var(--Complementry);
  border: 2px solid var(--Accent);
  padding: 10px 20px;
  font-family: var(--RubicFont);
  border-radius: 60px;
  font-weight: 500;
  font-size: 16px;
  color: var(--Accent);
}
.navSearch.search-toggle.active::after {
  font-size: 25px;
  background: var(--Accent);
  color: var(--Primary) !important;
  font-weight: 600;
}

.InnerPageBody {
  /* padding: 150px 0 10%; */
  padding: 10px 0 10%;
  background: #FFFFFF;
  margin-top: 40px;
}
.manufactureBanner {
  padding: 8% 5% 0;
  margin-top: 180px;
}

.ourProducts .left::after {
  border-bottom: 100px solid #d9d9d9;
  border-left: 48vw solid transparent;
  border-right: 48vw solid transparent;
}
.companyOverview ul.newTabs{
  margin: 0;
}
.companyOverview ul.newTabs li .nav-link.active,
.companyOverview ul.newTabs li .nav-link,
.companyOverview ul.newTabs li.nav-item .nav-link{
  font-size: 19px;
  padding: 1.11rem 0.50rem;
}
a.navSearch.search-toggle::after, .navSearch.search-toggle.active::after {
  font-size: 30px;
  margin-top: -10px;
}
.companyOverview .right,
.manufacturingRow .right,
.PackagingServicesRow .left {
  padding: 12% 8% 12%;
}
.manufacturingContent h2,
.PackagingServicesContent h2 {
  font-size: 23px;
  padding: 5% 0px 1%;
  text-align: left;
}
.workTogether h3 {
  font-size: 38px;
}
.overviewContent ul.newTabs li.nav-item .nav-link,
.overviewContent ul.newTabs li.nav-item .nav-link.active {
  font-size: 19px;
  text-align: center;
  padding: 1.5rem 1rem;
}
.footerRow{
  padding-bottom: 2rem;
}
.footer {
  padding: 0rem 2rem;
}
.footer h4 {
  margin-top: 3rem;
}

.menuProductList li a .productIcon {
  font-size: 70px;
  width: 20%;
}
.menuProductList li a .productText {
  font-size: 23px;
  font-weight: 400;
  padding: 10px;
  width: 80%;
}
.menuSVG {
  height: 71px;
  width: 71px;
}
.contactForm a.redBtn{
  width: 100%;
}
.menuProductList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0;
      padding: 0;
}
.menuProductList li a {
  color: var(--Accent) !important ;
}
#menu a.redBtn{
  width: 100%;
  text-align: center;
}
.menu {
  width: 100%;
}
.AboutCol {
  padding: 5% 0;
  color: var(--Accent);
}
.AboutCol ul li a {
  font-size: 19px;
  font-weight: 500;
  color: var(--Accent);
}
.container, .container-sm {
  max-width: 100%;
}
.AboutCol ul li {
  padding: 30px 0;
  border-bottom: 1px solid var(--Accent);
  list-style: none;
  width: 100%;
}
#manufacturingMenuTabContent .rightsideImg{
  display: none;
}

.bannerBottomBox ul {
  width: 94%;
  padding: 0;
  margin: 0px auto 0px;
}

.previewContent h2 {
  font-size: 34px;
  line-height: 48px;
  margin: 0 2.5rem 2rem;
  text-align: center;
  position: relative;
}

.manufacturingRow ul.MSTabs li .nav-link.active,
 .manufacturingRow ul.MSTabs li .nav-link:hover,
 .manufacturingRow ul.MSTabs li .nav-link,
 .PackagingServicesRow ul.MSTabs li .nav-link.active,
  .PackagingServicesRow ul.MSTabs li .nav-link:hover,
  .PackagingServicesRow ul.MSTabs li .nav-link,

  .manufactureLinks ul.MSTabs li .nav-link.active,
   .manufactureLinks ul.MSTabs li .nav-link:hover,
   .manufactureLinks ul.MSTabs li .nav-link{
  font-size:19px;
  padding: 2rem 1.5rem;

}
.AboutCol p {
  margin: 0;
}
.companyOverview .leftbg {
  width: 100%;
  padding: 15% 0 0;
}
.InnerBannerTitle {
  padding: 90px 20px 20px 20px;
}
.InnerBannerTitle p {
  font-weight: 500;
  font-size: 24px;
  margin: 2% 5%;
}
.overviewContent ul.newTabs {
  margin: 0 0%;
}
.InnerPageTabsContent h2{
  text-align: left;
}
.InnerPageTabsContent .blendingImage {
  margin: 5% auto 10% !important;
  height: fit-content;
}
.megamenu.container{
  max-width: 100%;
}
.manufacturingMenu ul.manufacturingTabsMenu li a {
  padding: 26px 20px;
  font-size: 19px;
  color: var(--Accent) !important;
  border-bottom: 1px solid var(--Accent) !important;
  display: block;
  font-weight: 500;
  margin-bottom: 0;
}
.navScrolled {
  top: 100px;
}

.breadcrumbsBlue {
  top: 100px;
}
.companyOverview ul.newTabs{
  width: 100%;
}
.prodctUnitsRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  justify-content: center;
}
.bx-wrapper .bx-pager {
  display: flex ;
  align-items: end;
  justify-content: end;
  padding-right: 20px;
}


.footerRow.container .row{
  display: grid ;
      align-items: baseline;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      gap: 0px;
}

.home_banner{
  margin-top: 38px;
}

.contactContent .left {
  background: var(--Primary);
  padding: 1% 50px;
  width: 100%;
  height: 100%;
  margin-top: 0;
  gap: 50px;
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(1, 1fr);
}

}


@media(max-width:420px){
  .homeBanner .caption {
    width: 90%;
    top: 25%;
}
  .ourProducts .left::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 0;
    border-bottom: 50px solid #d9d9d9;
        border-left: 70vw solid transparent;
        border-right: 30vw solid transparent;
}
.BottomBtn {
  position: fixed;
  bottom: 80px;
  right: 30px;
  display: none;
}

.navbar-left, .navbar-right {
  flex: 0 0 auto;
  width: auto;
  margin-left: 15px;
}
.InnerPageTabsContent .blendingImage {
  height: 175px;
}
ul.productList li{
  width: 160px;
}
ul.productList li a .icon {
  width: 120px;
  height: 120px;
  font-size: 4.5rem;
}
ul.productList li a p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 0;
}
}
@media (max-width:676px){
.workTogether .data .btnRow {
  display: flex;
  margin: 5rem 0;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
}}

@media (max-width:599px){
  .manufactureBanner h2,
  .PackagingServicesRow .right h3 ,
  .productInfo h2,
  .topHeader h1,
  .unitsBg h2
  {
    font-size: 28px;
  }
  .manufactureBanner h3,
  .qualityAssurance .data p,
  .ProcutCount .ListOfProducts,
  .productInfo h4,
  .ourJourney .left h3,
  .contactForm h3 {
    font-size: 19px;

  }
  .manufactureBanner p,
  .PackagingServicesContent h4,
  .ProductTopContent p,
  .unitsBg p,
  .overviewContent .right p{
    font-size: 16px;
  }
  .InnerPageTabsContent h2,
  .qualityAssurance .data h5,
  .InnerPageTabsContent h3,
  .contactForm h1 {
    font-size: 23px !important;
  }

  .InnerPageTabsContent p {
    font-size: 16px !important;
  }
  ul.CapsulesList ul.TabletsList li {
    font-size: 16px;
  }
 ul.TabletsList li,
 .manufacturingContent p {
    font-size: 16px;
  }
  ul.TabletCoatingList li,
.previewContent p {
    font-size: 16px;
  }
  ul.BlendingList li,
  ul.BlisterList li,
  ul.productList li a p {
    font-size: 16px;
  }

  a.getStartedBtn,
  a.redBtn{
    font-size: 14px;
  }
  .qualityAssurance .data h3,
  .ProductTopContent h2,
  .ourJourney .left h2,
  .InnerBannerTitle,
  .contactBanner {
    font-size: 34px;
  }
  .slider-container{
    display: none;
  }
  .companyOverview{
    margin-top: 24px;
  }
}

.summary-view {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 1rem;
}

.summary-content {
  max-height: 60vh;
  overflow-y: auto;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 1rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.summary-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.success-message {
  text-align: center;
  padding: 2rem;
  background: #e8f5e9;
  border-radius: 8px;
  color: #2e7d32;
}

.form-navigation {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.radio-group {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
}

.is-invalid {
  border-color: #dc3545;
}

.text-danger {
  color: #dc3545;
  margin-top: 0.25rem;
}