/* :root {
  --white-100: hsl(206, 5%, 100%);
  --white-200: hsl(206, 5%, 90%);
  --white-300: hsl(206, 5%, 80%);
  --white-400: hsl(206, 5%, 65%);
  --white-500: hsl(206, 5%, 45%);
  --black-100: hsl(210, 21%, 12%);
  --black-200: hsl(210, 21%, 9%);
  --black-300: hsl(210, 21%, 6%);
  --black-400: hsl(210, 21%, 3%);
  --black-500: hsl(210, 21%, 1%);
  --night-100: hsl(217, 27%, 20%);
  --night-200: hsl(217, 27%, 16%);
  --night-300: hsl(217, 27%, 12%);
  --night-400: hsl(215, 27%, 8%);
  --night-500: hsl(215, 27%, 4%);
  --pink-100: hsl(326, 85%, 90%);
  --pink-200: hsl(327, 87%, 80%);
  --pink-300: hsl(329, 86%, 70%);
  --pink-400: hsl(330, 81%, 60%);
  --pink-500: hsl(333, 71%, 50%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
} */



*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  box-sizing: inherit;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

/* body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: clamp(1rem, calc(0.97rem + 0.14vw), 1.09rem);
  font-weight: normal;
  line-height: 1.5;
  color: var(--black-300);
  background-color: var(--Accent);
}
body.darkmode {
  color: var(--Accent);
  background-color: var(--night-400);
} */

main {
  overflow: hidden;
}

Link,
button {
  cursor: pointer;
  user-select: none;
  border: none;
  outline: none;
  background: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.nav-tabs .nav-link {
  margin-bottom: 0px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
/* Example CSS for the mobile menu */


@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
.navContent{
  width: 1440px;
  margin: 0 auto;
}

.section {
  margin: 0 auto;
  padding: 6rem 0 2rem;
}

.container {
  max-width: 75rem;
  height: auto;
  margin-inline: auto;
  padding-inline: 1.5rem;
}

.centered {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  border-radius: 0.25rem;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.btn-primary {
  padding: 0.75rem 1.5rem;
  color: var(--Accent);
  background-color: var(--pink-500);
  box-shadow: var(--shadow-medium);
}
.btn-darken {
  padding: 0.75rem 1.5rem;
  color: var(--Accent);
  background-color: var(--night-300);
  box-shadow: var(--shadow-medium);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 105;
  margin: 0 au1to;
  background-color: var(--Accent);
  box-shadow: var(--shadow-medium);
  padding: 18px 0;
}


.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--pink-500);
  text-transform: uppercase;
  order: 1;
}
.darkmode .brand {
  color: var(--pink-300);
}

.navbar-auto{
    height: 4rem;
    margin: 0px auto !important;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  /* justify-content: center !important; */
  width: 86%;
  height: 4rem;
  margin: 0 auto;
  font-family: "Rubik", sans-serif;
  padding: 0 !important;
}
.navbar-left {
  display: flex;
  align-items: center;
  flex: 0 0 15%;
}
.navbar-center {
  display: flex;
}
.navbar-right {

  flex: 0 0 5%;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  display: none;
}

.menu-inner li .menu-link i {
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
 
 
 }

.menuProductList{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  padding: 0;
  width:fit--webkit-fill-available;
  
}
.menuProductList li{
  list-style: none;
  padding: 0;
  margin: 10px;
  display: flex;
  
}   

.menuProductList li a{
  border: 1px solid var(--Primary);
  color: var(--Primary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  
}

.menuProductList li a .productIcon{
  background: var(--Primary);
  padding:0 10px;
  font-size: 60px;
  display: flex;
  flex-direction: row;
  color: var(--Accent);
  width: 30%;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 82px;
}

.menuProductList li a .productIcon .icon-womenHealth:before {
  content: '\e81e';
  margin-left: -10px;
}


.menuProductList li a .productText{
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  width: 70%;
}

.manufacturingMenu{
  margin: 0;
}
.manufacturingMenu ul.manufacturingTabsMenu{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.manufacturingMenu ul.manufacturingTabsMenu li{
  padding: 0;
  margin: 0;
  list-style: none;
}

.manufacturingMenu ul.manufacturingTabsMenu li a{
 
  padding: 20px 20px;
  font-size: 20px;
  color: var(--Primary);
  border-bottom: 1px solid var(--Primary);
  display: block;
  font-weight: 500;
}
.manufacturingMenu ul.manufacturingTabsMenu li a:hover,
.manufacturingMenu ul.manufacturingTabsMenu li a.active{
  background-color: var(--Primary);
  color: var(--Accent);
  border-radius: 0;
  
  border-bottom: 0px solid var(--Accent) !important;
}

.manufacturingMenu .rightsideImg{
  width: 100%;
  height: auto;
}


.search-input:focus {
  outline: none;
}
.menuSVG{
  height: auto;
    width: 82px;
    padding: 0;
    /* filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%); */
    filter: invert(1);
}


#header .search-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 0;
  transform: translateY(100%);
  background-color: var(--Primary);
  transition: all 0.3s;
  z-index: 105;
  
}
#header .search-box .search-Row {
  width: 100%;
  height: 100%;
  padding: 0 1em 0 3em;
  border: 0;
  /* background: var(--Primary) url(../images/searchIcon.png) no-repeat 21px 21px; */
  color: #fff;  
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px
}
#header .search-box .search-Row .search-input {
  font-size: 24px;
  font-family:var(--RubicFont);
  color: #FFF;
  background: transparent;
  border: none;
  opacity: 0;
  width: 400px;
}
#header .search-box .search-Row img{
  width: 35px;
  opacity: 0;
}

#header .search-box .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
#header.show .search-box {
  max-height: 90px;
}
#header.show .search-box .search-input,
#header.show .search-box .search-Row img {
  opacity: 1;
}


.menu-header {
  display: none;
  box-shadow: var(--shadow-medium);
}
.menu-item {
  display: inline-block;
  line-height: 1.5;
  padding: 0;
  /* padding: 0 0.55rem; */
}
.menu-link {
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--black-300);
  text-transform: capitalize;
  transition: all 0.25s ease;
  padding: 36px 15px;
}
.menu-link > i.bx {
  font-size: 1.35rem;
  margin-left: 0.25rem;
  transform: rotate(90deg);
}
.darkmode .menu-link {
  color: var(--Accent);
}
.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  font-size: 1.45rem;
  color: var(--black-300);
  transition: all 0.25s ease;
}
.menu-inner{
  font-size: 18px;
  font-weight: 500;
  line-height: 27px; /* 150% */
/* letter-spacing: 0.54px; */
margin-bottom: 0;
padding: 0;

}

.menu-inner li.menu-item:hover,
.menu-inner li.menu-item:hover a.menu-link{
  background: var(--Primary);
  color: var(--Accent);
}




.submenu {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 1.75rem;
  padding: 1rem 1rem;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  border-top: 2px solid var(--pink-400);
  background-color: var(--Accent);
  box-shadow: var(--shadow-medium);
  transition: all 0.25s ease-in-out;
}

.submenu-inner {
  flex: 0 0 25%;
  padding: 0 1rem;
}
.submenu-title {
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 0;
  color: var(--pink-500);
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.submenu-item {
  display: block;
  line-height: 1;
  margin: 0 auto;
}
.submenu-link {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  padding: 0.75rem 0;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--black-300);
  transition: all 0.25s ease-in-out;
}

.submenu-image {
  display: block;
  width: 100%;
  height: auto;
  margin-block: 0.5rem;
  object-fit: cover;
}


.manufacturingMenu_megamenu {
  left: 48% !important;
  width: auto;
  height: auto;
  margin: 0 auto;
  
}


.packagingServicesMenu_megamenu {
    left: 64% !important;
    width: auto;
    height: auto;
    margin: 0 auto;
    
}

.catlogMenu_magamenu {
  right: -15%;
    left: inherit !important;
    width: auto;
    height: auto;
    margin: 0 auto;
    
}


.megamenu {
  left: 49%;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  transform: translateX(-50%);
}
.megamenu-column-1 {
  left: 65%;
  max-width: 15rem;
  width: 100%;
  height: auto;
}
.megamenu-column-rt {
  
  max-width: 20rem;
  width: 100%;
  height: auto;
  right: -38%;
}
.megamenu-column-4 {
  display: flex;
  flex-wrap: wrap;
  max-width: 100rem;
  height: auto;
  margin: 0 auto;
}





.switch {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  margin-right: 0.5rem;
}
.switch-light, .switch-dark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in;
}
.switch-light {
  font-size: 1.3rem;
  visibility: visible;
  color: var(--black-300);
}
.darkmode .switch-light {
  font-size: 0rem;
  visibility: hidden;
}
.switch-dark {
  font-size: 0rem;
  visibility: hidden;
  color: var(--Accent);
}
.darkmode .switch-dark {
  font-size: 1.3rem;
  visibility: visible;
}

.overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.45s ease-in-out;
}





.burger {
  position: relative;
  display: block;
  cursor: pointer;
  width: 1.6rem;
  height: 1rem;
  margin-right: 1rem;
  opacity: 0;
  visibility: hidden;
  background: transparent;
}


.burger-line {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  opacity: 1;
  border-radius: 1rem;
  background: var(--black-400);
}
.darkmode .burger-line {
  background: var(--Accent);
}
.burger-line:nth-child(1) {
  top: 0px;
}
.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}
.burger-line:nth-child(3) {
  top: 1rem;
}
.burger {
  display: none;
}

.desktop{
  display: block;
}
.mobile{
  display: none;
}

.mobileTopGetQuote{
  display: none;
}

@media(min-width:1440px){
 
}

@media(max-width:1500px){
  .navContent {
    width: 96%;
    margin: 0 auto;
}

.navbar-right {
  margin-right: 0;
}

}

@media only screen and (min-width: 1240px) {
  .menu-dropdown:hover > .submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  .manufacturingMenu {
    margin: 0px 0 0 0px;
}


}

@media(max-width: 1250px) {
  .navbar {
    width: 100%;
  }
  .menu-inner {
      font-size: 18px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0;
      margin-bottom: 0;
      padding-left: 10px;
      font-family: var(--RubicFont);
  }
  .navbar-auto {
    width: 100% !important;
        height: auto;
        margin: 0px auto !important;
  }
  .menu-link {
    padding: 30px 8px;
  }
  .header {
    padding: 0px 0;
  }
  
}
 
@media(max-width:1024px){
  .menu-link {
      padding: 30px 0px;
  }
  .navbar-left {
    flex: 0 0 12%;
  }
  .menu-inner {
    font-size: 18px;
  }
  .menu-inner li .menu-link i {
    font-size: 12px;
  }
  .navbar-right {
    display: flex;
    flex: 0 0 0%;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;
}
a.navSearch.search-toggle::after {
  width: 42px;
  height: 42px;
  background-color: var(--Primary);
  border-radius: 10px;
}
}

@media only screen and (max-width: 1240px){
  .navbar-left, .navbar-right {
    flex: 0 0 auto;
    width: auto;
    margin-left: 15px;
  }
  .navbar-right {
    align-items: center;
    margin-right: 40px;
  }
  .navbar-center {
    flex: 0 0 100%;
    order: 3;
    align-items: center;
  }
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .manufacturingMenu .rightsideImg {
    text-align: center;
}
.CatlogImage {
  margin: 30px auto;
  width: 100%;
}
.navbar-right {
   margin-left: 10px;
}
a.navSearch {
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
}
.menu-link {
  padding: 40px 0px;
}
}


@media only screen and (max-width: 1240px) {
  .menu {
    position: fixed;
    top: 100px;
    right: 0;
    width: 85%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    transform: translate(100%);
    background-color: var(--Accent);
    transition: all 0.4s ease-in-out;
  }
  .menu.is-active {
    transform: translate(0%);

  }
  .darkmode .menu {
    background-color: var(--night-300);
  }
  
}


@media only screen and (max-width: 1240px) {


.menuProductList {
    height: 70vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    padding: 0;
    overflow-y: auto;
  }

  .menu-header {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 4rem;
    z-index: 110;
    visibility: hidden;
    background: transparent;
  }
  .menu-header.is-active {
    visibility: visible;
    background-color: var(--Accent);
  }
  .menu-header.is-active > .menu-arrow {
    display: block;
    transform: rotateZ(180deg);
    font-size: 20px;
    font-weight: 800;
    margin-top: -34px;
    margin-right: -8px;
  }
  .darkmode .menu-header.is-active {
    background-color: var(--night-100);
  }
  .menu-arrow {
    display: none;
    cursor: pointer;
    width: 3rem;
    height: 4rem;
    text-align: center;
  }
  .menu-arrow > i.bx {
    font-size: 1.5rem;
    line-height: 4rem;
    color: var(--black-300);
    transition: all 0.25s ease;
  }
  .darkmode .menu-arrow > i.bx {
    color: var(--Accent);
  }
  .menu-title {
    font-family: inherit;
    font-size: 25px;
    font-weight: 500;
    line-height: inherit;
    text-transform: capitalize;
    color: var(--black-300);
  }
  .darkmode .menu-title {
    color: var(--Accent);
  }
  .menu-inner {
    height: 100%;
    margin-top: -3rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 20px;
    margin-right: 20px;
  }
  .menu-item {
    display: block;
    line-height: 1;
    padding-inline: 0;
  }
  .menu-link {
    justify-content: space-between;
    height: 2.5rem;
    line-height: 2.5rem;
    padding-inline: 1.25rem;
    font-size: 24px;
    flex-direction: row-reverse;
  }
  .menu-inner li .menu-link i {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 5px;
    transform: rotate(90deg);
    margin-top: -18px;
  }
  .menu-link > i.bx {
    margin-left: 0.5rem;
    transform: rotate(0deg);
  }
  .AboutCol {
    padding: 10px 0;
  }
  .AboutCol ul li {
    padding: 20px 0;
  }
}


@media only screen and (max-width: 1240px){
  .submenu {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    min-width: auto;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    box-shadow: none;
    transform: translateX(0%);
  }
  .submenu.is-active {
    display: block;
    width: 100% !important;
    /* margin-top: 100px; */
    height: 90vh;
    overflow: auto;
  }
  .submenu-inner {
    flex: 0 0 100%;
    padding: 0rem;
  }
  .submenu-list {
    margin-bottom: 1rem;
  }
  .submenu-link {
    display: block;
  }
  .submenu-image {
    margin-top: 0;
  }
  .manufacturingMenu_megamenu,
  .packagingServicesMenu_megamenu,
  .catlogMenu_magamenu{
    left: 0 !important;
    /* transform: translateX(0%) !important; */
  }
}
@media only screen and (max-width: 1240px) {
  .megamenu {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    min-width: auto;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    transform: translateX(0%);
    box-shadow: none;
  }
  .menuSVG {
    height: 72px;
    width: 72px;
  }
  .navbar-right {
    display: flex;
  }
}

@media only screen and (max-width: 1240px) {
  .overlay.is-active {
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 1240px) {
  .burger {
    opacity: 1;
    visibility: visible;
    display: block;
    color: var(--Accent);
    font-size: 52px;
    top: -33px;
    margin-left: 20px;
  }
  /* .burger.is-active span{
    color: var(--Primary);
    background: var(--Accent);
    border-radius: 20%;
  } */
  
  /* .burger.is-active span::after{
    content: "\E854";
    font-family: "pharmalinefonts"; 
    font-size: 50px;
  } */

  .navbar {
    justify-content: space-between !important;
    background: var(--Primary);
    height: 100px;
    max-width: 100% !important;
  }
  .menu-item {
    display: block;
    line-height: 1;
    padding-inline: 0;
    border-bottom: 1px solid var(--Primary);
}
}

@media(max-width:905px){
  .menu {
      width: 85%;
  }
}
@media (max-width:767px){
  .menu {
    width: 85%;
}
}
@media(max-width:599px) {
  .menu {
    position: fixed;
    top: 100px;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    transform: translate(100%);
    background-color: var(--Accent);
    transition: all 0.4s ease-in-out;
}
.megamenu {
  background-color: var(--Primary);
  
}

.menu-inner {
  height: 100%;
  margin-top: -4rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.menu-link {
  font-size: 19px;
  padding: 36px 20px;
}
.menuProductList li a .productIcon {
  padding: 10px 10px;
  font-size: 60px;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.menuProductList {
  
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
}
.manufacturingMenu .rigrightsideImg{
  display: none;
}
.hideImg{
  display: none;
}
  
  .mobileTopGetQuote{
    background: var(--Accent);
    padding: 30px;
    position: absolute;
    top:100px;
    width: 100%;
    display: block;
    height: 90px;
    box-shadow: var(--shadow-medium);
    display: none;
  }

  .menu.is-active {
    transform: translate(0%);
    z-index: 120;
}



}

@media(max-width: 599px) {
  .menu-title,
  .AboutCol h2,
  .manufacturingMenu ul.manufacturingTabsMenu li a {
    font-size: 19px;
  }
  .menuProductList li a .productText{
    font-size: 16px;
  }
}
  
