@font-face {
  font-family: 'pharmalinefonts';
  src: url('../font/pharmalinefonts.eot?83136556');
  src: url('../font/pharmalinefonts.eot?83136556#iefix') format('embedded-opentype'),
       url('../font/pharmalinefonts.woff2?83136556') format('woff2'),
       url('../font/pharmalinefonts.woff?83136556') format('woff'),
       url('../font/pharmalinefonts.ttf?83136556') format('truetype'),
       url('../font/pharmalinefonts.svg?83136556#pharmalinefonts') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pharmalinefonts';
    src: url('../font/pharmalinefonts.svg?83136556#pharmalinefonts') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "pharmalinefonts";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-downarrow:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-rightArrow:before { content: '\e802'; } /* '' */
.icon-lineArrowRight:before { content: '\e803'; } /* '' */
.icon-lineArrowBottom:before { content: '\e804'; } /* '' */
.icon-lineArrowLeft:before { content: '\e805'; } /* '' */
.icon-arrowlinedown:before { content: '\e806'; } /* '' */
.icon-arrowup:before { content: '\e807'; } /* '' */
.icon-bloodcells:before { content: '\e808'; } /* '' */
.icon-bonehealth:before { content: '\e809'; } /* '' */
.icon-WheyProteins:before { content: '\e80a'; } /* '' */
.icon-digestive:before { content: '\e80b'; } /* '' */
.icon-email:before { content: '\e80c'; } /* '' */
.icon-funnelsimple:before { content: '\e80d'; } /* '' */
.icon-heart:before { content: '\e80f'; } /* '' */
.icon-immune:before { content: '\e810'; } /* '' */
.icon-location-2:before { content: '\e811'; } /* '' */
.icon-minus:before { content: '\e812'; } /* '' */
.icon-mood:before { content: '\e813'; } /* '' */
.icon-overweight:before { content: '\e814'; } /* '' */
.icon-paperclip:before { content: '\e815'; } /* '' */
.icon-plus:before { content: '\e816'; } /* '' */
.icon-brainhealth:before { content: '\e817'; } /* '' */
.icon-sleep:before { content: '\e818'; } /* '' */
.icon-sports:before { content: '\e819'; } /* '' */
.icon-textindent:before { content: '\e81a'; } /* '' */
.icon-women:before { content: '\e81b'; } /* '' */
.icon-Icon_x:before { content: '\e81c'; } /* '' */
.icon-call:before { content: '\e81d'; } /* '' */
.icon-Functionalfoods:before { content: '\e81e'; } /* '' */
.icon-Greenpowders:before { content: '\e81f'; } /* '' */
.icon-Seeds:before { content: '\e820'; } /* '' */
.icon-Herbalblends:before { content: '\e821'; } /* '' */
.icon-PlantProteins:before { content: '\e822'; } /* '' */
.icon-Cognitive:before { content: '\e823'; } /* '' */
.icon-Electrolyte:before { content: '\e824'; } /* '' */
.icon-Energy:before { content: '\e825'; } /* '' */
.icon-Gummies:before { content: '\e826'; } /* '' */
.icon-Hair_Skin_Nails:before { content: '\e827'; } /* '' */
.icon-bone_health:before { content: '\e828'; } /* '' */
.icon-Antioxidant:before { content: '\e829'; } /* '' */
.icon-Bloodsugar:before { content: '\e82a'; } /* '' */
.icon-Vitamin_D:before { content: '\e82b'; } /* '' */
.icon-brain_health:before { content: '\e82c'; } /* '' */
.icon-Calcium:before { content: '\e82d'; } /* '' */
.icon-ArrowLeft:before { content: '\e82e'; } /* '' */
.icon-ArrowRight:before { content: '\e82f'; } /* '' */
.icon-close:before { content: '\e830'; } /* '' */
.icon-cold:before { content: '\e831'; } /* '' */
.icon-diegest:before { content: '\e832'; } /* '' */
.icon-electro:before { content: '\e833'; } /* '' */
.icon-download:before { content: '\e834'; } /* '' */
.icon-filter:before { content: '\e836'; } /* '' */
.icon-fruit:before { content: '\e837'; } /* '' */
.icon-email-1:before { content: '\e838'; } /* '' */
.icon-headache:before { content: '\e839'; } /* '' */
.icon-hairskinnail:before { content: '\e83a'; } /* '' */
.icon-gummies:before { content: '\e83b'; } /* '' */
.icon-healthwellbeaing:before { content: '\e83c'; } /* '' */
.icon-heart-1:before { content: '\e83d'; } /* '' */
.icon-iron:before { content: '\e83f'; } /* '' */
.icon-jointhealth:before { content: '\e840'; } /* '' */
.icon-kids:before { content: '\e841'; } /* '' */
.icon-leaf:before { content: '\e842'; } /* '' */
.icon-liver_support:before { content: '\e843'; } /* '' */
.icon-location-1:before { content: '\e844'; } /* '' */
.icon-less:before { content: '\e845'; } /* '' */
.icon-menshealth:before { content: '\e846'; } /* '' */
.icon-immunue_support:before { content: '\e847'; } /* '' */
.icon-moodsupport:before { content: '\e848'; } /* '' */
.icon-nav:before { content: '\e849'; } /* '' */
.icon-nuts:before { content: '\e84a'; } /* '' */
.icon-phone:before { content: '\e84b'; } /* '' */
.icon-performance:before { content: '\e84c'; } /* '' */
.icon-pill:before { content: '\e84d'; } /* '' */
.icon-probiotic:before { content: '\e84e'; } /* '' */
.icon-protion:before { content: '\e84f'; } /* '' */
.icon-search-1:before { content: '\e850'; } /* '' */
.icon-sleepsupport:before { content: '\e851'; } /* '' */
.icon-natural:before { content: '\e852'; } /* '' */
.icon-sports-1:before { content: '\e853'; } /* '' */
.icon-powder:before { content: '\e854'; } /* '' */
.icon-throat:before { content: '\e856'; } /* '' */
.icon-vision:before { content: '\e857'; } /* '' */
.icon-vitamin_d:before { content: '\e858'; } /* '' */
.icon-weightmanagement:before { content: '\e859'; } /* '' */
.icon-womens_health:before { content: '\e85a'; } /* '' */
.icon-add:before { content: '\e85b'; } /* '' */
.icon-antiox:before { content: '\e85c'; } /* '' */
.icon-arrow_down:before { content: '\e85d'; } /* '' */
.icon-arrow_left:before { content: '\e85e'; } /* '' */
.icon-arrow_right:before { content: '\e85f'; } /* '' */
.icon-arrow_tail_left:before { content: '\e860'; } /* '' */
.icon-arrow_tale_down:before { content: '\e861'; } /* '' */
.icon-arrow_tale_right:before { content: '\e862'; } /* '' */
.icon-arrow_tale_up:before { content: '\e863'; } /* '' */
.icon-arrow_up:before { content: '\e864'; } /* '' */
.icon-attachment:before { content: '\e865'; } /* '' */
.icon-location:before { content: '\e8c6'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
