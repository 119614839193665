/* Slider container (visible area) */
.slider-container {
	margin-top: 100px;
	position: relative;
	overflow: hidden;
	width: 98%;
	height: 80vh; /* Adjust height as needed */
  }
  
  /* Wrapper for images (used for horizontal sliding) */
  .slider-wrapper {
	display: flex;
	transition: transform 1s ease-in-out; /* Smooth sliding */
  }
  
  /* Each image slide */
  .slider-image {
	flex: 0 0 100%; /* Each image takes up full width */
	height: 80vh; /* Match the container height */
	background-size: cover;
	background-position: center;
  }
  
  /* Slider overlay for the first slide */
  .slider-overlay {
	margin: 0;
    z-index: 10;
    padding-top: 12%;
    padding-bottom: 20px;
    padding-left: 100px;
    color: #fff;
    text-align: left;
    height: 100vh;
    width:50%;

  }
  
  .slider-title {
	font-size: 34px;
	font-weight: bold;
	margin-bottom: 10px;
  }
  
  .slider-paragraph {
	font-size: 18px;
	margin-bottom: 20px;
  }
  
  .getStartedBtn {
	background-color: #0d6efd;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
  }
  
  /* Dots navigation */
  .dots-container {
	position: absolute;
	bottom: 20px;
	left: 92%;
	transform: translateX(-50%);
	display: flex;
	gap: 10px;
	margin-bottom: 16px;
  }
  
  .scroll-downs{
	position: absolute;
	bottom: 20px;
  }

  /* Dot styles */
  .dot {
	width: 15px;
	height: 15px;
	background-color: #bbb;
	border-radius: 50%;
	cursor: pointer;
  }
  
  .dot.active {
	background-color: #0d6efd; /* Active dot color */
  }

  @media screen and (min-width: 3840px) {
    .slider-container {
		height: 80vh;
	  }
    
    .slider-image {
        height: 80vh;
    }
    
    .slider-title {
        font-size: 60px;
    }
    
    .slider-paragraph {
        font-size: 40px;
    }
}

  @media screen and (max-width: 3024px) {
	.slider-container {
		height: 1500px; /* Adjust height as needed */
	  }

	  .slider-image {
		height: 1500px;
	  }

	  .slider-title {
		font-size: 56px;
	  }
	
	  .slider-paragraph {
		font-size: 36px;
	  }
  }
  
  @media screen and (max-width: 2560px) {
	.slider-container {
		height: 600px; /* Adjust height as needed */
	  }

	  .slider-image {
		height: 600px;
	  }

	  .slider-title {
		font-size: 36px;
	  }
	
	  .slider-paragraph {
		font-size: 22px;
	  }
  }

  @media screen and (max-width: 1880px) {
	/* .slider-container {
		height: 100vh;
	  }

	  .slider-image {
		height: 100vh;
	  } */
	  .slider-title {
		font-size: 22px;
	  }
	
	  .slider-paragraph {
		font-size: 16px;
		margin-bottom: 0;
	  }
  }

  /* Responsive styles */
  @media screen and (max-width: 1064px) {
	.slider-container {
		height: 500px; /* Adjust height as needed */
	  }
	.slider {
	  height: 400px;
	}
  
	.slider-overlay {
		width:60%;
		padding-left: 32px;
	}

	.slider-title {
	  font-size: 22px;
	}
  
	.slider-paragraph {
	  font-size: 16px;
	  margin-bottom: 0;
	}
  
	.getStartedBtn {
	  font-size: 16px;
	  padding: 8px 16px;
	}
  
	.dot {
	  width: 10px;
	  height: 10px;
	}

	.slider-image {
		height: 500px;
	  }
  }
  
  @media screen and (max-width: 900px) {
	.slider-container {
		height: 400px; /* Adjust height as needed */
	  }
	.slider {
	  height: 300px;
	}
	.slider-overlay {
		width:60%;
		padding-left: 24px;
		padding-top: 6%;
		padding-bottom: 10px;
	}

	.slider-title {
	  font-size: 22px;
	}
  
	.slider-paragraph {
	  font-size: 16px;
	}
  
	.getStartedBtn {
	  font-size: 14px;
	  padding: 6px 12px;
	}
	.scroll-downs{
		display: block;
	  }
	  
  	.dots-container {
		left: 50%;
	  }

	  
	.mousey{
		display: none;
	  }
	  
	.dot {
	  width: 8px;
	  height: 8px;
	  left: 50%;
	}

	.slider-image {
		height: 400px;
	  }
  }

  @media screen and (max-width: 850px) {
	.slider-container {
		height: 400px; /* Adjust height as needed */
	  }
	  .slider-image {
		height: 400px;
	  }
	  .slider-title {
		font-size: 20px;
	  }
	
	  .slider-paragraph {
		font-size: 14px;
	  }
  }

  @media screen and (max-width: 790px) {
	.slider-container {
		height: 350px; /* Adjust height as needed */
	  }
	  .slider-image {
		height: 350px;
	  }
	  .slider-title {
		font-size: 18px;
	  }
	
	  .slider-paragraph {
		font-size: 14px;
	  }
  }
  
  @media screen and (max-width: 480px) {
	.slider-container {
		height: 160px;
	}
	.slider-image {
		height: 160px;
	}
	.slider {
	  height: 200px;
	}
	.slider-overlay {
		width:100%;
		padding-left: 16px;

	}
	.slider-title {
	  font-size: 20px;
	}
  
	.slider-paragraph {
	  font-size: 14px;
	}
  
	.getStartedBtn {
	  font-size: 12px;
	  padding: 4px 10px;
	}

	.dots-container {
		left: 50per;
	  }


	.mousey{
		display: none;
	  }
	  
	.dot {
	  width: 6px;
	  height: 6px;
	  left:50%;
	}
  }
	